export const courses = [{ name: "ALEKS 0000 Passed ALEKS Exam" },
{ name: "ACCT 2301 Introductory Financial Accounting" },
{ name: "ACCT 2302 Introductory Management Accounting" },
{ name: "ACCT 3100 Professional Development" },
{ name: "ACCT 3101 Professional Program in Accounting Lyceum" },
{ name: "ACCT 3200 Introduction to Business and Professional Development" },
{ name: "ACCT 3322 Integrated Accounting Information Systems" },
{ name: "ACCT 3331 Intermediate Financial Accounting I" },
{ name: "ACCT 3332 Intermediate Financial Accounting II" },
{ name: "ACCT 3341 Cost Management Systems" },
{ name: "ACCT 3350 Fundamentals of Taxation" },
{ name: "ACCT 4301 Database Systems" },
{ name: "ACCT 4302 Accounting Research" },
{ name: "ACCT 4334 Auditing" },
{ name: "ACCT 4336 Financial Statement Analysis" },
{ name: "ACCT 4337 Business Valuation" },
{ name: "ACCT 4340 Survey of Accounting Software" },
{ name: "ACCT 4342 Analysis and Design of Accounting Systems" },
{ name: "ACCT 4395 Capstone Senior Project - Accounting" },
{ name: "ACCT 4V00 Special Topics" },
{ name: "ACCT 4V80 Professional Internship in Accounting" },
{ name: "ACTS 4301 Long Term Actuarial Mathematics I" },
{ name: "ACTS 4302 Investment and Financial Markets I" },
{ name: "ACTS 4303 Long Term Actuarial Mathematics II" },
{ name: "ACTS 4304 Short Term Actuarial Mathematics I" },
{ name: "ACTS 4305 Short Term Actuarial Mathematics II" },
{ name: "ACTS 4306 Actuarial Probability as Problem Solving" },
{ name: "ACTS 4307 Statistics for Risk Modeling" },
{ name: "ACTS 4308 Actuarial Financial Mathematics" },
{ name: "ACTS 4309 Investment and Financial Markets II" },
{ name: "AHST 1303 Survey of Western Art History: Ancient to Medieval" },
{ name: "AHST 1304 Survey of Western Art History: Renaissance to Modern" },
{ name: "AHST 2331 Understanding Art" },
{ name: "AHST 2V71 Independent Study in Art History" },
{ name: "AHST 3313 Medieval Art and Architecture" },
{ name: "AHST 3315 Renaissance Art and Architecture" },
{ name: "AHST 3316 Baroque Art and Architecture" },
{ name: "AHST 3317 Pioneers of Modern Art" },
{ name: "AHST 3318 Contemporary Art" },
{ name: "AHST 3319 Twentieth Century European Art: Avant-Garde and Aftermath" },
{ name: "AHST 3320 Art in Historical Context" },
{ name: "AHST 3321 Chinese Art History" },
{ name: "AHST 3322 Modern Architecture" },
{ name: "AHST 3324 History of Photography" },
{ name: "AHST 4342 Topics in Art History" },
{ name: "AHST 4V71 Independent Study in Art History" },
{ name: "AMS 2300 American Popular Culture" },
{ name: "AMS 2311 Social Change and Photography in America" },
{ name: "AMS 2341 American Studies for the Twenty-First Century" },
{ name: "AMS 2390 Topics in American Studies" },
{ name: "AMS 3302 American Cultures" },
{ name: "AMS 3316 Interpersonal Communication" },
{ name: "AMS 3322 American Ethnic Experience: Immigrants After 1945" },
{ name: "AMS 3327 American Studies: Histories, Theories, Methodologies" },
{ name: "AMS 3350 Crisis Communication" },
{ name: "AMS 3374 Entrepreneurs in America" },
{ name: "AMS 4300 Oral and Written Communication for the Classroom" },
{ name: "AMS 4304 Communication in America" },
{ name: "AMS 4305 World History for Teachers" },
{ name: "AMS 4324 Motherhood and the Technological Womb" },
{ name: "AMS 4327 The Culture of Bullying in America" },
{ name: "AMS 4360 Rebels and Reformers: Women and Alcohol in America" },
{ name: "AMS 4379 Special Topics in American Studies" },
{ name: "AMS 4381 Senior Honors in American Studies" },
{ name: "AMS 4385 Professional Communications in Business" },
{ name: "AMS 4V80 Independent Study" },
{ name: "ARAB 1311 Beginning Arabic I" },
{ name: "ARAB 1312 Beginning Arabic II" },
{ name: "ARAB 2311 Intermediate Arabic I" },
{ name: "ARAB 2312 Intermediate Arabic II" },
{ name: "ARHM 1100 First Year Seminar" },
{ name: "ARHM 2340 Creativity" },
{ name: "ARHM 2342 Connections in the Arts and Humanities" },
{ name: "ARHM 2343 Science and the Humanities" },
{ name: "ARHM 2344 World Cultures" },
{ name: "ARHM 2V71 Independent Study in the Arts and Humanities" },
{ name: "ARHM 3100 Topics in Values and Science" },
{ name: "ARHM 3342 Advanced Interdisciplinary Studies in the Arts and Humanities" },
{ name: "ARHM 4V71 Independent Study in the Arts and Humanities" },
{ name: "ARHM 4V99 Art, Humanities, and Culture" },
{ name: "ARTS 1301 Exploration of the Arts" },
{ name: "ARTS 1316 Drawing Foundations" },
{ name: "ARTS 2315 Topics in Visual Art" },
{ name: "ARTS 2316 Painting Foundations" },
{ name: "ARTS 2350 Photography: Design" },
{ name: "ARTS 2380 Visual Design Foundations" },
{ name: "ARTS 2381 Introduction to Sculpture" },
{ name: "ARTS 2V71 Independent Study in Visual Arts" },
{ name: "ARTS 3311 Contemporary Art Practices" },
{ name: "ARTS 3313 Intermediate Visual Design" },
{ name: "ARTS 3315 The Art of Tinkering: Creating at the intersection of Art, Science and Technology" },
{ name: "ARTS 3340 Topics in Studio Art" },
{ name: "ARTS 3341 Chinese Calligraphy" },
{ name: "ARTS 3363 Typography, Technology, Image" },
{ name: "ARTS 3364 Silkscreen Printmaking" },
{ name: "ARTS 3366 Intermediate Drawing" },
{ name: "ARTS 3367 Figure Drawing" },
{ name: "ARTS 3368 Mixed Media" },
{ name: "ARTS 3369 Intermediate Painting" },
{ name: "ARTS 3371 Photography: Black/White" },
{ name: "ARTS 3372 Photography: Color Concepts" },
{ name: "ARTS 3373 Printmaking" },
{ name: "ARTS 3375 Sculpture" },
{ name: "ARTS 3376 Time-Based Art" },
{ name: "ARTS 3377 Photography: Altered Image" },
{ name: "ARTS 3378 Figure Sculpture" },
{ name: "ARTS 3379 Photography: New Media" },
{ name: "ARTS 3381 Video Painting" },
{ name: "ARTS 3382 Color as Subject" },
{ name: "ARTS 4305 The Art of Graphic Design" },
{ name: "ARTS 4308 Image/Text" },
{ name: "ARTS 4310 Studies in Contemporary Exhibitions" },
{ name: "ARTS 4366 Advanced Drawing" },
{ name: "ARTS 4368 Advanced Visual Arts" },
{ name: "ARTS 4369 Advanced Painting" },
{ name: "ARTS 4372 Advanced Photography" },
{ name: "ARTS 4V71 Independent Study in Visual Arts" },
{ name: "ATCM 1100 First Year Seminar" },
{ name: "ATCM 2300 Introduction to TechnoCulture" },
{ name: "ATCM 2301 Computer Imaging" },
{ name: "ATCM 2302 Design I" },
{ name: "ATCM 2303 Project Management for Arts, Technology, and Emerging Communication I" },
{ name: "ATCM 2310 Animation and Game Fundamentals" },
{ name: "ATCM 2315 Animation Origins and Techniques" },
{ name: "ATCM 2320 Critical Media Research Foundations" },
{ name: "ATCM 2321 Reading Media Critically" },
{ name: "ATCM 2322 Media Histories" },
{ name: "ATCM 2330 Digital Art and Social Practice" },
{ name: "ATCM 2335 Internet Studio I" },
{ name: "ATCM 2340 Visual Communication" },
{ name: "ATCM 2343 Emerging Media Art Histories" },
{ name: "ATCM 2345 Principles of Digital Audio" },
{ name: "ATCM 2350 Time-Based Media" },
{ name: "ATCM 2355 Survey of Digital Fabrication" },
{ name: "ATCM 2360 Design Histories" },
{ name: "ATCM 2375 History of Games" },
{ name: "ATCM 2380 Introduction to Emerging Communication" },
{ name: "ATCM 3300 Code Plus Culture" },
{ name: "ATCM 3301 Writing for ATEC" },
{ name: "ATCM 3303 Project Management for Arts, Technology, and Emerging Communication II" },
{ name: "ATCM 3304 Character Design" },
{ name: "ATCM 3305 Computer Animation I" },
{ name: "ATCM 3306 Modeling and Texturing I" },
{ name: "ATCM 3307 Lighting and Composition I" },
{ name: "ATCM 3308 Rigging I" },
{ name: "ATCM 3309 Visual Story I" },
{ name: "ATCM 3310 Procedural Animation" },
{ name: "ATCM 3311 Scripting for Animation" },
{ name: "ATCM 3312 Surfacing and Shading I" },
{ name: "ATCM 3313 2D Animation" },
{ name: "ATCM 3315 Animation Studies" },
{ name: "ATCM 3320 Critical Media Theories" },
{ name: "ATCM 3321 Networked Identities" },
{ name: "ATCM 3323 Reading in a Networked Era" },
{ name: "ATCM 3325 Global Media Cultures" },
{ name: "ATCM 3331 Critical Making" },
{ name: "ATCM 3335 Internet Studio II" },
{ name: "ATCM 3336 Design Research Methods" },
{ name: "ATCM 3337 Interaction Design I" },
{ name: "ATCM 3338 Motion Graphics I" },
{ name: "ATCM 3340 Design II" },
{ name: "ATCM 3345 Audio Technologies" },
{ name: "ATCM 3346 Audio Productions Lab I" },
{ name: "ATCM 3350 Digital Video Production I" },
{ name: "ATCM 3355 Computer Modeling for Digital Fabrication" },
{ name: "ATCM 3356 Projection Mapping Lab" },
{ name: "ATCM 3357 Digital Fabrication Lab I" },
{ name: "ATCM 3365 Game Design I" },
{ name: "ATCM 3366 Game Studies I" },
{ name: "ATCM 3367 Level Design I" },
{ name: "ATCM 3368 Scripting for Games I" },
{ name: "ATCM 3369 Games and Narrative I" },
{ name: "ATCM 3370 Virtual Environments I" },
{ name: "ATCM 3371 User Experience Design for Games" },
{ name: "ATCM 3372 Sound Design for Games" },
{ name: "ATCM 3375 Game Systems Design" },
{ name: "ATCM 3380 Media Psychology" },
{ name: "ATCM 3381 Media and Communication Research Methods" },
{ name: "ATCM 3382 Communicating Research" },
{ name: "ATCM 3385 Social Networks" },
{ name: "ATCM 3388 Attitudes and Behaviors" },
{ name: "ATCM 3395 Topics in Arts, Technology, and Emerging Communication" },
{ name: "ATCM 4304 World Building" },
{ name: "ATCM 4305 Computer Animation II" },
{ name: "ATCM 4306 Modeling and Texturing II" },
{ name: "ATCM 4307 Lighting and Composition II" },
{ name: "ATCM 4308 Rigging II" },
{ name: "ATCM 4309 Pre-Production Design II" },
{ name: "ATCM 4310 Motion Capture Animation" },
{ name: "ATCM 4311 Experimental Lab" },
{ name: "ATCM 4312 Digital Sculpting" },
{ name: "ATCM 4313 Visual Effects" },
{ name: "ATCM 4314 Character Effects" },
{ name: "ATCM 4315 Computer Animation III" },
{ name: "ATCM 4316 Animation Lab I" },
{ name: "ATCM 4317 Animation Lab II" },
{ name: "ATCM 4318 Motion Capture Lab" },
{ name: "ATCM 4319 Topics in Animation" },
{ name: "ATCM 4320 Political Economy of Digital Media" },
{ name: "ATCM 4322 Disability, Technology, and Media" },
{ name: "ATCM 4323 Feminism, Technology, and Media" },
{ name: "ATCM 4325 Race, Technology, and Media" },
{ name: "ATCM 4326 Mediated Textuality" },
{ name: "ATCM 4327 Privacy and Surveillance" },
{ name: "ATCM 4330 Culture Jamming" },
{ name: "ATCM 4332 Critical Media Studies Lab I" },
{ name: "ATCM 4333 Critical Media Studies Lab II" },
{ name: "ATCM 4334 Topics in Critical Media Studies" },
{ name: "ATCM 4337 Interaction Design II" },
{ name: "ATCM 4338 Motion Graphics II" },
{ name: "ATCM 4340 Strategic Design" },
{ name: "ATCM 4345 Digital Audio Processing" },
{ name: "ATCM 4346 Audio Productions Lab II" },
{ name: "ATCM 4350 Digital Video Production II" },
{ name: "ATCM 4357 Digital Fabrication Lab II" },
{ name: "ATCM 4364 Topics in Design and Production" },
{ name: "ATCM 4365 Game Design II" },
{ name: "ATCM 4366 Game Studies II" },
{ name: "ATCM 4367 Level Design II" },
{ name: "ATCM 4368 Scripting for Games II" },
{ name: "ATCM 4370 Virtual Environments II" },
{ name: "ATCM 4373 Animation for Games" },
{ name: "ATCM 4374 Interactives Lab" },
{ name: "ATCM 4375 Game Design III" },
{ name: "ATCM 4376 Game Production Lab I" },
{ name: "ATCM 4377 Game Production Lab II" },
{ name: "ATCM 4379 Topics in Games" },
{ name: "ATCM 4380 Communication, Media, and Information Technology" },
{ name: "ATCM 4384 Ethics in New Media, Technology, and Communication" },
{ name: "ATCM 4385 Children and Media" },
{ name: "ATCM 4386 Journalism in the Networked Age" },
{ name: "ATCM 4388 Political Communication" },
{ name: "ATCM 4395 Advanced Topics in Arts, Technology, and Emerging Media" },
{ name: "ATCM 4397 Senior Seminar" },
{ name: "ATCM 4398 Capstone Project" },
{ name: "ATCM 4399 Senior Honors in Arts, Technology, and Emerging Communication" },
{ name: "ATCM 4V96 Independent Study in Arts, Technology, and Emerging Communication" },
{ name: "BA 1100 Business Basics" },
{ name: "BA 1310 Making Choices in Free Market Systems" },
{ name: "BA 1320 Business in a Global World" },
{ name: "BA 3100 Professional Development" },
{ name: "BA 3200 Introduction to Business and Professional Development" },
{ name: "BA 3300 Advanced Topics in Capitalism: Ideas and Challenges That Shape Our World" },
{ name: "BA 4010 ENACTUS Participation" },
{ name: "BA 4070 Conference Attendance US" },
{ name: "BA 4071 Conference Attendance International" },
{ name: "BA 4072 Conference Presentation US" },
{ name: "BA 4073 Conference Presentation International" },
{ name: "BA 4074 Student Leadership and Mentoring" },
{ name: "BA 4080 Participation in Internal Competition" },
{ name: "BA 4081 Participation in Local Competition" },
{ name: "BA 4082 Participation in Regional Competition" },
{ name: "BA 4083 Participation in National Competition" },
{ name: "BA 4090 Management Internship" },
{ name: "BA 4095 Social Sector Engagement and Community Outreach Practicum" },
{ name: "BA 4101 Careers in Management" },
{ name: "BA 4V00 Special Topics" },
{ name: "BA 4V10 ENACTUS Service" },
{ name: "BA 4V90 Management Internship" },
{ name: "BBSU 1100 First Year Seminar" },
{ name: "BBSU 3310 Community-Based Service Learning" },
{ name: "BCOM 1300 Introduction to Professionalism and Communication in Business" },
{ name: "BCOM 3100 Professional Development" },
{ name: "BCOM 3200 Introduction to Business and Professional Development" },
{ name: "BCOM 3300 Professionalism and Communication in Business" },
{ name: "BCOM 3310 Business Communication" },
{ name: "BCOM 4300 Managing Communications in Business" },
{ name: "BCOM 4310 Strategic Business Communications" },
{ name: "BCOM 4350 Advanced Business Communication" },
{ name: "BIOL 1300 Body Systems with Lab" },
{ name: "BIOL 1318 Human Genetics" },
{ name: "BIOL 1350 Body Systems" },
{ name: "BIOL 1V00 Topics in Biological Sciences" },
{ name: "BIOL 1V01 Topics in Biological Sciences with Lab" },
{ name: "BIOL 1V95 Individual Instruction in Biology" },
{ name: "BIOL 2111 Introduction to Modern Biology Workshop I" },
{ name: "BIOL 2112 Introduction to Modern Biology Workshop II" },
{ name: "BIOL 2281 Introductory Biology Laboratory" },
{ name: "BIOL 2311 Introduction to Modern Biology I" },
{ name: "BIOL 2312 Introduction to Modern Biology II" },
{ name: "BIOL 2350 Biological Basis of Health and Disease" },
{ name: "BIOL 2V00 Topics in Biological Sciences" },
{ name: "BIOL 2V01 Topics in Biological Sciences with Lab" },
{ name: "BIOL 2V95 Individual Instruction in Biology" },
{ name: "BIOL 3101 Classical and Molecular Genetics Workshop" },
{ name: "BIOL 3102 Eukaryotic Molecular and Cell Biology Workshop" },
{ name: "BIOL 3161 Biochemistry Workshop I" },
{ name: "BIOL 3162 Biochemistry Workshop II" },
{ name: "BIOL 3301 Classical and Molecular Genetics" },
{ name: "BIOL 3302 Eukaryotic Molecular and Cell Biology" },
{ name: "BIOL 3303 Introduction to Microbiology" },
{ name: "BIOL 3305 Evolutionary Analysis" },
{ name: "BIOL 3312 Introduction to Programming for Biological Sciences" },
{ name: "BIOL 3315 Epigenetics" },
{ name: "BIOL 3318 Forensic Biology" },
{ name: "BIOL 3320 Applied Genetics" },
{ name: "BIOL 3335 Microbial Physiology" },
{ name: "BIOL 3336 Protein and Nucleic Acid Structure" },
{ name: "BIOL 3351 Medical Cell Biology" },
{ name: "BIOL 3355 Clinical Pathophysiology" },
{ name: "BIOL 3357 Mammalian Physiology with Lab" },
{ name: "BIOL 3361 Biochemistry I" },
{ name: "BIOL 3362 Biochemistry II" },
{ name: "BIOL 3370 Exercise Physiology" },
{ name: "BIOL 3380 Biochemistry Laboratory" },
{ name: "BIOL 3385 Medical Histology" },
{ name: "BIOL 3388 Honey Bee Biology" },
{ name: "BIOL 3455 Human Anatomy and Physiology with Lab I" },
{ name: "BIOL 3456 Human Anatomy and Physiology with Lab II" },
{ name: "BIOL 3520 General Microbiology with Lab" },
{ name: "BIOL 3V00 Topics in Biological Sciences" },
{ name: "BIOL 3V01 Topics in Biological Sciences with Lab" },
{ name: "BIOL 3V15 Research Practicum for UT-PACT" },
{ name: "BIOL 3V40 Topics in Molecular and Cell Biology" },
{ name: "BIOL 3V81 Clinical Medicine I" },
{ name: "BIOL 3V82 Clinical Medicine II" },
{ name: "BIOL 3V83 Clinical Medicine III" },
{ name: "BIOL 3V84 Clinical Medicine IV" },
{ name: "BIOL 3V90 Undergraduate Readings in Biology" },
{ name: "BIOL 3V91 Undergraduate Research in Biology" },
{ name: "BIOL 3V93 Undergraduate Research in Biochemistry" },
{ name: "BIOL 3V94 Topics in Biology: Individual Instruction" },
{ name: "BIOL 3V96 Undergraduate Research in Molecular and Cell Biology" },
{ name: "BIOL 4302 TA Apprenticeship" },
{ name: "BIOL 4305 Molecular Evolution" },
{ name: "BIOL 4310 Cellular Microbiology" },
{ name: "BIOL 4315 Genes, Disease and Therapeutics" },
{ name: "BIOL 4317 Cellular and Molecular Medicine of Human Diseases" },
{ name: "BIOL 4320 Cell Migration in Health and Disease" },
{ name: "BIOL 4325 Nutrition and Metabolism" },
{ name: "BIOL 4330 Advanced Research in Molecular and Cell Biology" },
{ name: "BIOL 4337 Seminal Papers in Biology" },
{ name: "BIOL 4341 Genomics" },
{ name: "BIOL 4345 Immunobiology" },
{ name: "BIOL 4350 Medical Microbiology" },
{ name: "BIOL 4353 Molecular Biology of HIV/AIDS" },
{ name: "BIOL 4356 Molecular Neuropathology" },
{ name: "BIOL 4357 Molecular Neuropathology II" },
{ name: "BIOL 4360 Evolution and Development" },
{ name: "BIOL 4365 Advanced Human Physiology" },
{ name: "BIOL 4366 Molecular Biology of Cancer" },
{ name: "BIOL 4371 General and Molecular Virology" },
{ name: "BIOL 4380 Cell and Molecular Biology Laboratory" },
{ name: "BIOL 4385 Oral Histology and Embryology" },
{ name: "BIOL 4390 Senior Readings in Molecular and Cell Biology" },
{ name: "BIOL 4391 Senior Research in Molecular and Cell Biology" },
{ name: "BIOL 4399 Senior Honors Research for Thesis in Molecular and Cell Biology" },
{ name: "BIOL 4461 Biophysical Chemistry" },
{ name: "BIOL 4V00 Special Topics in Biology" },
{ name: "BIOL 4V01 Topics in Biological Sciences with Lab" },
{ name: "BIOL 4V40 Special Topics in Molecular and Cell Biology" },
{ name: "BIOL 4V95 Advanced Topics in Biology" },
{ name: "BIOL 4V99 Senior Honors Research in Molecular and Cell Biology" },
{ name: "BIS 1100 Interdisciplinary Studies Freshman Seminar" },
{ name: "BIS 2190 Library Research Skills" },
{ name: "BIS 2V90 Topics in Interdisciplinary Studies" },
{ name: "BIS 3320 The Nature of Intellectual Inquiry" },
{ name: "BIS 3V03 Educational Issues" },
{ name: "BIS 4303 Senior Honors in Interdisciplinary Studies" },
{ name: "BIS 4306 Strategies for Diversity in Education" },
{ name: "BIS 4310 Co-op Education" },
{ name: "BIS 4V01 Special Topics" },
{ name: "BIS 4V02 Independent Study" },
{ name: "BIS 4V04 Internship" },
{ name: "BLAW 2301 Business and Public Law" },
{ name: "BLAW 3301 Employment Law" },
{ name: "BLAW 4301 International Law" },
{ name: "BLAW 4305 Introduction to Patent Law" },
{ name: "BLAW 4310 Current Issues in Business and Law" },
{ name: "BMEN 1100 Introduction to Bioengineering I" },
{ name: "BMEN 1208 Introduction to Bioengineering II" },
{ name: "BMEN 2320 Statics" },
{ name: "BMEN 2V99 Topics in Biomedical Engineering" },
{ name: "BMEN 3110 Biomedical Transport Processes Laboratory" },
{ name: "BMEN 3120 Biomedical Circuits and Instrumentation Laboratory" },
{ name: "BMEN 3130 Engineering Physiology Laboratory" },
{ name: "BMEN 3150 Biomedical Engineering Laboratory" },
{ name: "BMEN 3170 Digital Circuits Laboratory" },
{ name: "BMEN 3200 Biomedical Engineering Fundamentals and Design" },
{ name: "BMEN 3220 Electrical and Electronic Circuits in Biomedical Engineering Lab" },
{ name: "BMEN 3302 Bioengineering Signals and Systems" },
{ name: "BMEN 3310 Fluid Mechanics and Transport Processes in Biomedical Engineering" },
{ name: "BMEN 3315 Thermodynamics and Physical Chemistry in Biomedical Engineering" },
{ name: "BMEN 3318 Introduction to Engineered Biomaterials" },
{ name: "BMEN 3320 Electrical and Electronic Circuits in Biomedical Engineering" },
{ name: "BMEN 3325 Advanced Computational Tools for Biomedical Engineering" },
{ name: "BMEN 3330 Engineering Physiology of the Human Body" },
{ name: "BMEN 3331 Cell and Molecular Engineering" },
{ name: "BMEN 3332 Quantitative Physiology for Engineers" },
{ name: "BMEN 3350 Biomedical Component and System Design" },
{ name: "BMEN 3360 Thermodynamics" },
{ name: "BMEN 3370 Digital Circuits" },
{ name: "BMEN 3380 Medical Imaging Systems and Methods" },
{ name: "BMEN 3399 Introductory Biomechanics" },
{ name: "BMEN 3402 Signals and Systems for Biomedical Engineering" },
{ name: "BMEN 3V99 Topics in Biomedical Engineering" },
{ name: "BMEN 4110 Biomedical Feedback Systems Laboratory" },
{ name: "BMEN 4301 Introduction to Medical Device Development" },
{ name: "BMEN 4310 Feedback Systems in Biomedical Engineering" },
{ name: "BMEN 4320 Intermediate Electrical Systems" },
{ name: "BMEN 4342 Introduction to Robotics" },
{ name: "BMEN 4350 Applied Sensor Technology" },
{ name: "BMEN 4355 Finite Element Analysis in Biomedical Engineering" },
{ name: "BMEN 4360 Biomaterials and Medical Devices" },
{ name: "BMEN 4370 Biomedical Image Processing" },
{ name: "BMEN 4375 Biomedical Engineering Data Analysis" },
{ name: "BMEN 4388 Senior Design Project I" },
{ name: "BMEN 4389 Senior Design Project II" },
{ name: "BMEN 4399 Senior Honors in Biomedical Engineering" },
{ name: "BMEN 4V95 Undergraduate Topics in Biomedical Engineering" },
{ name: "BMEN 4V97 Independent Study in Biomedical Engineering" },
{ name: "BMEN 4V98 Engineering Practicum" },
{ name: "BPS 4305 Strategic Management" },
{ name: "BPS 4307 Corporations, Politics and Society" },
{ name: "BPS 4395 Capstone Senior Project - Business" },
{ name: "CE 1100 Introduction to Electrical and Computer Engineering" },
{ name: "CE 1202 Introduction to Electrical Engineering II" },
{ name: "CE 1337 Computer Science I" },
{ name: "CE 2305 Discrete Mathematics for Computing I" },
{ name: "CE 2310 Introduction to Digital Systems" },
{ name: "CE 2336 Computer Science II" },
{ name: "CE 3120 Digital Circuits Laboratory" },
{ name: "CE 3161 Social Issues and Ethics in Engineering" },
{ name: "CE 3201 Electrical and Computer Engineering Fundamentals-I Laboratory" },
{ name: "CE 3202 Electrical and Computer Engineering Fundamentals-II Laboratory" },
{ name: "CE 3301 Electrical Network Analysis" },
{ name: "CE 3303 Discrete-Time Signals and Systems" },
{ name: "CE 3310 Electronic Devices" },
{ name: "CE 3311 Electronic Circuits" },
{ name: "CE 3320 Digital Circuits" },
{ name: "CE 3345 Data Structures and Introduction to Algorithmic Analysis" },
{ name: "CE 3354 Software Engineering" },
{ name: "CE 4201 Electrical and Computer Engineering Laboratory in Computing Systems and Computer Engineering" },
{ name: "CE 4202 Electrical and Computer Engineering Laboratory in Circuits" },
{ name: "CE 4203 Electrical and Computer Engineering Laboratory in Signals and Systems" },
{ name: "CE 4204 Electrical and Computer Engineering Laboratory in Devices" },
{ name: "CE 4205 Electrical and Computer Engineering Laboratory in Power Electronics and Energy Systems" },
{ name: "CE 4304 Computer Architecture" },
{ name: "CE 4337 Organization of Programming Languages" },
{ name: "CE 4348 Operating Systems Concepts" },
{ name: "CE 4370 Embedded Systems" },
{ name: "CE 4388 Senior Design Project I" },
{ name: "CE 4389 Senior Design Project II" },
{ name: "CE 4390 Computer Networks" },
{ name: "CE 4399 Senior Honors in Computer Engineering" },
{ name: "CE 4V95 Undergraduate Topics in Computer Engineering" },
{ name: "CE 4V97 Independent Study in Computer Engineering" },
{ name: "CE 4V98 Undergraduate Research in Computer Engineering" },
{ name: "CGS 2301 Cognitive Science" },
{ name: "CGS 3325 Historical Perspectives on Psychology: Mind and Machines since 1600" },
{ name: "CGS 3340 Experimental Projects in Cognitive Science" },
{ name: "CGS 3342 Cognitive and Neural Modeling Laboratory" },
{ name: "CGS 3361 Cognitive Psychology" },
{ name: "CGS 4193 Internship Preparation" },
{ name: "CGS 4314 Intelligent Systems Analysis" },
{ name: "CGS 4315 Intelligent Systems Design" },
{ name: "CGS 4320 Psychology of Reasoning" },
{ name: "CGS 4352 Human Computer Interactions I" },
{ name: "CGS 4353 Human Computer Interactions II" },
{ name: "CGS 4359 Cognitive Neuroscience" },
{ name: "CGS 4362 Perception" },
{ name: "CGS 4364 Attention and Memory" },
{ name: "CGS 4385 Neuropsychology" },
{ name: "CGS 4386 Adult Development and Aging" },
{ name: "CGS 4389 Developmental Cognitive Neuroscience" },
{ name: "CGS 4390 Directed Research and Writing" },
{ name: "CGS 4391 Writing and Independent Study" },
{ name: "CGS 4394 Internship in Cognitive Science" },
{ name: "CGS 4395 Co-op Fieldwork" },
{ name: "CGS 4397 Thesis Research" },
{ name: "CGS 4V75 Honors Seminar" },
{ name: "CGS 4V90 Special Topics in Cognitive Science" },
{ name: "CGS 4V96 Teaching Internship" },
{ name: "CGS 4V98 Directed Research" },
{ name: "CGS 4V99 Individual Study" },
{ name: "CHEM 1111 General Chemistry Laboratory I" },
{ name: "CHEM 1112 General Chemistry Laboratory II" },
{ name: "CHEM 1115 Honors Freshman Chemistry Laboratory I" },
{ name: "CHEM 1116 Honors Freshman Chemistry Laboratory II" },
{ name: "CHEM 1301 General Chemistry for Engineers" },
{ name: "CHEM 1311 General Chemistry I" },
{ name: "CHEM 1312 General Chemistry II" },
{ name: "CHEM 1315 Honors Freshman Chemistry I" },
{ name: "CHEM 1316 Honors Freshman Chemistry II" },
{ name: "CHEM 2123 Introductory Organic Chemistry Laboratory I" },
{ name: "CHEM 2125 Introductory Organic Chemistry Laboratory II" },
{ name: "CHEM 2127 Honors Organic Chemistry Laboratory I" },
{ name: "CHEM 2128 Honors Organic Chemistry Laboratory II" },
{ name: "CHEM 2130 Introductory Organic Chemistry for Medical Science Laboratory" },
{ name: "CHEM 2323 Introductory Organic Chemistry I" },
{ name: "CHEM 2324 Introductory Organic Chemistry for Engineers" },
{ name: "CHEM 2325 Introductory Organic Chemistry II" },
{ name: "CHEM 2327 Honors Organic Chemistry I" },
{ name: "CHEM 2328 Honors Organic Chemistry II" },
{ name: "CHEM 2330 Introductory Organic Chemistry for Medical Science" },
{ name: "CHEM 2401 Introductory Quantitative Methods in Chemistry" },
{ name: "CHEM 2V01 Topics in Chemistry" },
{ name: "CHEM 3321 Physical Chemistry I" },
{ name: "CHEM 3322 Physical Chemistry II" },
{ name: "CHEM 3341 Inorganic Chemistry I" },
{ name: "CHEM 3361 Biochemistry I" },
{ name: "CHEM 3362 Biochemistry II" },
{ name: "CHEM 3471 Advanced Chemical Synthesis Laboratory" },
{ name: "CHEM 3472 Instrumental Analysis" },
{ name: "CHEM 3V92 Undergraduate Research in Biochemistry" },
{ name: "CHEM 4335 Polymer Chemistry" },
{ name: "CHEM 4355 Computational Modeling" },
{ name: "CHEM 4381 Green Chemistry and Green Fuels" },
{ name: "CHEM 4390 Research and Advanced Writing in Chemistry" },
{ name: "CHEM 4399 Research and Advanced Writing in Chemistry for Honors Students" },
{ name: "CHEM 4473 Physical Measurements Laboratory" },
{ name: "CHEM 4V01 Topics in Chemistry" },
{ name: "CHEM 4V91 Research in Chemistry" },
{ name: "CHIN 1311 Beginning Chinese I" },
{ name: "CHIN 1312 Beginning Chinese II" },
{ name: "CHIN 2311 Intermediate Chinese I" },
{ name: "CHIN 2312 Intermediate Chinese II" },
{ name: "CHIN 2350 Business Chinese" },
{ name: "CHIN 3330 Medical Chinese" },
{ name: "CHIN 3365 Advanced Chinese I" },
{ name: "CHIN 4301 Advanced Chinese Conversation" },
{ name: "CHIN 4364 Advanced Chinese Culture" },
{ name: "CLDP 2314 Lifespan Development" },
{ name: "CLDP 3303 Normal Language Development" },
{ name: "CLDP 3305 Language and Literacy Development" },
{ name: "CLDP 3310 Child Development" },
{ name: "CLDP 3332 Social and Personality Development" },
{ name: "CLDP 3336 Infancy" },
{ name: "CLDP 3338 Adolescence" },
{ name: "CLDP 3339 Educational Psychology" },
{ name: "CLDP 3342 Exceptional Children" },
{ name: "CLDP 3343 Children in a Changing World" },
{ name: "CLDP 3362 Cognitive Development" },
{ name: "CLDP 3365 Child Learning" },
{ name: "CLDP 3366 Motivation and Achievement" },
{ name: "CLDP 3394 Research and Evaluation Methods" },
{ name: "CLDP 3494 Research and Evaluation Methods" },
{ name: "CLDP 4193 Internship Preparation" },
{ name: "CLDP 4308 Language Disorders in Children" },
{ name: "CLDP 4344 Child Psychopathology" },
{ name: "CLDP 4345 Violence in the Family" },
{ name: "CLDP 4347 Marriage and Family Psychology" },
{ name: "CLDP 4389 Developmental Cognitive Neuroscience" },
{ name: "CLDP 4390 Directed Research and Writing" },
{ name: "CLDP 4391 Individual Study and Writing" },
{ name: "CLDP 4394 Internship" },
{ name: "CLDP 4395 Co-op Fieldwork" },
{ name: "CLDP 4397 Thesis Research" },
{ name: "CLDP 4V75 Honors Seminar" },
{ name: "CLDP 4V90 Special Topics in Child Learning and Development" },
{ name: "CLDP 4V96 Teaching Internship" },
{ name: "CLDP 4V98 Directed Research" },
{ name: "CLDP 4V99 Individual Study" },
{ name: "COMM 1311 Survey of Oral and Technology-based Communication" },
{ name: "COMM 1315 Public Speaking" },
{ name: "COMM 1320 Interpersonal Communication" },
{ name: "COMM 2314 Oral Interpretation" },
{ name: "COMM 2317 Topics in Communication" },
{ name: "COMM 2V71 Independent Study in Communications" },
{ name: "COMM 3320 Readers' Theater" },
{ name: "COMM 3321 Advanced Public Speaking" },
{ name: "COMM 3340 Small Group Communication" },
{ name: "COMM 3342 Advanced Topics in Communication" },
{ name: "COMM 3350 Intercultural Communication" },
{ name: "COMM 3351 History and Theory of Communication" },
{ name: "COMM 3352 Media and Culture" },
{ name: "COMM 4305 Communications Law" },
{ name: "COMM 4314 Persuasion and Interpersonal Influence" },
{ name: "COMM 4360 Communication Ethics" },
{ name: "COMM 4370 Communication and Leadership" },
{ name: "COMM 4371 Communication and Professionalism" },
{ name: "COMM 4375 Professional Communication in Medicine" },
{ name: "COMM 4V71 Advanced Independent Study in Communication" },
{ name: "CRIM 1301 Introduction to Criminal Justice" },
{ name: "CRIM 1307 Introduction to Crime and Criminology" },
{ name: "CRIM 2306 Criminal Law" },
{ name: "CRIM 2308 Juvenile Law" },
{ name: "CRIM 2313 Police and Society" },
{ name: "CRIM 2316 Corrections" },
{ name: "CRIM 2317 Criminal Prosecution and Court Process" },
{ name: "CRIM 3300 Crime and Civil Liberties" },
{ name: "CRIM 3301 Theories of Justice" },
{ name: "CRIM 3302 Advanced Criminology" },
{ name: "CRIM 3303 Advanced Criminal Justice" },
{ name: "CRIM 3304 Research Methods in Crime and Justice Studies" },
{ name: "CRIM 3307 Immigration and Crime" },
{ name: "CRIM 3309 Media and Crime" },
{ name: "CRIM 3310 Youth Crime and Justice" },
{ name: "CRIM 3311 American Crime: Truths, Half-Truths, and Lies" },
{ name: "CRIM 3312 Drugs and Crime" },
{ name: "CRIM 3319 Comparative Justice Systems" },
{ name: "CRIM 3320 Homicide and Capital Punishment" },
{ name: "CRIM 3323 Violence and Gun Control" },
{ name: "CRIM 3324 Gender, Crime, and Justice" },
{ name: "CRIM 3325 Victimology" },
{ name: "CRIM 3326 Victimless Crimes" },
{ name: "CRIM 3327 Violent Crime" },
{ name: "CRIM 4311 Crime and Justice Policy" },
{ name: "CRIM 4315 Race, Ethnicity, and Justice" },
{ name: "CRIM 4322 Senior Research Seminar" },
{ name: "CRIM 4323 Communities and Crime" },
{ name: "CRIM 4324 White Collar Crime" },
{ name: "CRIM 4336 Introduction to Terrorism" },
{ name: "CRIM 4337 Landmark Supreme Court Cases" },
{ name: "CRIM 4396 Selected Topics in Criminology" },
{ name: "CRIM 4V97 Independent Study in Criminology" },
{ name: "CRIM 4V98 Internship in Criminology" },
{ name: "CRIM 4V99 Senior Honors in Criminology" },
{ name: "CRWT 2301 Introduction to Creative Writing" },
{ name: "CRWT 2V71 Independent Study in Creative Writing" },
{ name: "CRWT 3306 Fiction Workshop" },
{ name: "CRWT 3308 Nonfiction Workshop" },
{ name: "CRWT 3330 Translation Workshop" },
{ name: "CRWT 3351 Poetry Workshop" },
{ name: "CRWT 3354 Screenwriting" },
{ name: "CRWT 4307 Advanced Fiction Workshop" },
{ name: "CRWT 4353 Advanced Poetry Workshop" },
{ name: "CRWT 4V71 Independent Study in Creative Writing" },
{ name: "CS 1134 Computer Science Laboratory" },
{ name: "CS 1136 Computer Science Laboratory" },
{ name: "CS 1200 Introduction to Computer Science and Software Engineering" },
{ name: "CS 1324 Introduction to Programming for Biomedical Engineers" },
{ name: "CS 1325 Introduction to Programming" },
{ name: "CS 1334 Programming Fundamentals for Non-Majors" },
{ name: "CS 1335 Computer Science I for Non-majors" },
{ name: "CS 1336 Programming Fundamentals" },
{ name: "CS 1337 Computer Science I" },
{ name: "CS 2305 Discrete Mathematics for Computing I" },
{ name: "CS 2335 Computer Science II for Non-majors" },
{ name: "CS 2336 Computer Science II" },
{ name: "CS 2337 Computer Science II" },
{ name: "CS 2V95 Individual Instruction in Computer Science" },
{ name: "CS 3149 Competitive Learning in Computer Science" },
{ name: "CS 3162 Professional Responsibility in Computer Science and Software Engineering" },
{ name: "CS 3305 Discrete Mathematics for Computing II" },
{ name: "CS 3333 Data Structures" },
{ name: "CS 2340 Computer Architecture" },
{ name: "CS 3341 Probability and Statistics in Computer Science and Software Engineering" },
{ name: "CS 3345 Data Structures and Introduction to Algorithmic Analysis" },
{ name: "CS 3354 Software Engineering" },
{ name: "CS 3360 Computer Graphics for Artists and Designers" },
{ name: "CS 3377 C/C++ Programming in a UNIX Environment" },
{ name: "CS 3385 Ethics, Law, Society, and Computing" },
{ name: "CS 3V95 Undergraduate Topics in Computer Science" },
{ name: "CS 4141 Digital Systems Laboratory" },
{ name: "CS 4301 Special Topics in Computer Science" },
{ name: "CS 4313 Neural Net Mathematics" },
{ name: "CS 4314 Intelligent Systems Analysis" },
{ name: "CS 4315 Intelligent Systems Design" },
{ name: "CS 4332 Introduction to Programming Video Games" },
{ name: "CS 4334 Numerical Analysis" },
{ name: "CS 4336 Advanced Java" },
{ name: "CS 4337 Organization of Programming Languages" },
{ name: "CS 4341 Digital Logic and Computer Design" },
{ name: "CS 4347 Database Systems" },
{ name: "CS 4348 Operating Systems Concepts" },
{ name: "CS 4349 Advanced Algorithm Design and Analysis" },
{ name: "CS 4352 Human Computer Interactions I" },
{ name: "CS 4353 Human Computer Interactions II" },
{ name: "CS 4361 Computer Graphics" },
{ name: "CS 4365 Artificial Intelligence" },
{ name: "CS 4371 Introduction to Big Data Management and Analytics" },
{ name: "CS 4372 Computational Methods for Data Scientists" },
{ name: "CS 4375 Introduction to Machine Learning" },
{ name: "CS 4376 Object-Oriented Design" },
{ name: "CS 4384 Automata Theory" },
{ name: "CS 4386 Compiler Design" },
{ name: "CS 4389 Data and Applications Security" },
{ name: "CS 4390 Computer Networks" },
{ name: "CS 4391 Introduction to Computer Vision" },
{ name: "CS 4392 Computer Animation" },
{ name: "CS 4393 Computer and Network Security" },
{ name: "CS 4394 Implementation of Modern Operating Systems" },
{ name: "CS 4395 Human Language Technologies" },
{ name: "CS 4396 Networking Laboratory" },
{ name: "CS 4397 Embedded Computer Systems" },
{ name: "CS 4398 Digital Forensics" },
{ name: "CS 4399 Senior Honors in Computer Science" },
{ name: "CS 4475 Capstone Project" },
{ name: "CS 4485 Computer Science Project" },
{ name: "CS 4V95 Undergraduate Topics in Computer Science" },
{ name: "CS 4V98 Undergraduate Research in Computer Science" },
{ name: "DANC 1305 Introduction to World Dance Forms" },
{ name: "DANC 1310 Understanding Dance" },
{ name: "DANC 2311 Topics in Dance" },
{ name: "DANC 2321 Stretch, Conditioning, Alignment" },
{ name: "DANC 2331 Dance Technique 1" },
{ name: "DANC 2332 Modern Dance 1" },
{ name: "DANC 2333 Jazz Dance 1" },
{ name: "DANC 2334 Ballet 1" },
{ name: "DANC 2336 Tap Dance 1" },
{ name: "DANC 2V71 Independent Study in Dance" },
{ name: "DANC 3332 Dance Technique 2" },
{ name: "DANC 3333 Modern Dance 2" },
{ name: "DANC 3334 Jazz Dance 2" },
{ name: "DANC 3335 Ballet 2" },
{ name: "DANC 3336 Latin Social Dance" },
{ name: "DANC 3340 Dance in Historical Context" },
{ name: "DANC 3342 Advanced Topics in Dance" },
{ name: "DANC 3345 Dance Performance" },
{ name: "DANC 3347 Dance Composition" },
{ name: "DANC 4313 Dance Technique 3" },
{ name: "DANC 4314 Modern Dance 3" },
{ name: "DANC 4315 Ballet 3" },
{ name: "DANC 4316 Jazz Dance 3" },
{ name: "DANC 4317 Dance Performance 2" },
{ name: "DANC 4318 Dance Technique 4" },
{ name: "DANC 4V71 Independent Study in Dance" },
{ name: "ECON 2001 Principles of Macroeconomics: Recitation" },
{ name: "ECON 2301 Principles of Macroeconomics" },
{ name: "ECON 2302 Principles of Microeconomics" },
{ name: "ECON 3310 Intermediate Microeconomic Theory" },
{ name: "ECON 3311 Intermediate Macroeconomic Theory" },
{ name: "ECON 3312 Money and Banking" },
{ name: "ECON 3315 Sports Economics" },
{ name: "ECON 3330 Economics of Health" },
{ name: "ECON 3332 Economic Geography" },
{ name: "ECON 3336 Economics of Education" },
{ name: "ECON 3337 Economics of Poverty and Inequality" },
{ name: "ECON 3369 Political Economy of Terrorism" },
{ name: "ECON 3381 Economic History" },
{ name: "ECON 4301 Game Theory" },
{ name: "ECON 4302 Urban and Regional Economics" },
{ name: "ECON 4310 Managerial Economics" },
{ name: "ECON 4320 Public Sector Economics" },
{ name: "ECON 4330 Law and Economics" },
{ name: "ECON 4332 Energy and Natural Resources Economics" },
{ name: "ECON 4333 Environmental Economics" },
{ name: "ECON 4334 Experimental Economics" },
{ name: "ECON 4336 Environmental Economic Theory and Policy" },
{ name: "ECON 4340 Labor Economics and Human Resources" },
{ name: "ECON 4342 Public Policies Toward Business" },
{ name: "ECON 4345 Industrial Organization" },
{ name: "ECON 4346 Technology, Economy, and Society" },
{ name: "ECON 4348 Business and Technology" },
{ name: "ECON 4351 Mathematical Economics" },
{ name: "ECON 4355 Econometrics" },
{ name: "ECON 4360 International Trade" },
{ name: "ECON 4362 Development Economics" },
{ name: "ECON 4381 History of Economic Ideas" },
{ name: "ECON 4382 International Finance" },
{ name: "ECON 4385 Business and Economic Forecasting" },
{ name: "ECON 4396 Selected Topics in Economics" },
{ name: "ECON 4V97 Independent Study in Economics" },
{ name: "ECON 4V98 Internship" },
{ name: "ECON 4V99 Senior Honors in Economics" },
{ name: "ECS 1100 Introduction to Engineering and Computer Science" },
{ name: "ECS 2361 Social Issues and Ethics in Science and Technology" },
{ name: "ECS 3301 Introduction to Nanoscience and Nanotechnology" },
{ name: "ECS 3310 Introduction to Materials Science" },
{ name: "ECS 3390 Professional and Technical Communication" },
{ name: "ECSC 3177 CS IPP Assignment" },
{ name: "ECSC 3179 ENG IPP Assignment" },
{ name: "ECSC 4300 Student Apprenticeship and Mentoring" },
{ name: "ECSC 4378 Professional Industrial Practice Program" },
{ name: "ED 3314 The American Public School" },
{ name: "ED 3315 Children's Literature" },
{ name: "ED 3339 Educational Psychology for Teachers" },
{ name: "ED 3340 Math Concepts for Teachers" },
{ name: "ED 3342 Classroom Management Grades EC - 6" },
{ name: "ED 3345 Art, Music, and Physical Development Grades EC - 6" },
{ name: "ED 3370 Curriculum and Instruction in Mathematics and Computer Science" },
{ name: "ED 3371 Curriculum and Instruction in the Natural Sciences" },
{ name: "ED 3380 Curriculum and Instruction in English" },
{ name: "ED 3382 Curriculum and Instruction in Social Studies" },
{ name: "ED 4343 Science Methods for Grades EC - 6" },
{ name: "ED 4344 Mathematics Methods for Elementary Teachers" },
{ name: "ED 4345 Social Studies Methods for Grades EC - 6" },
{ name: "ED 4351 ESL for K-12 Teachers" },
{ name: "ED 4352 Reading I: Learning to Read" },
{ name: "ED 4353 Reading in Secondary Content" },
{ name: "ED 4357 Diagnostic Reading" },
{ name: "ED 4358 Chess I: Using Chess in Schools" },
{ name: "ED 4359 Chess II: Institutional and Cultural Contexts of Chess" },
{ name: "ED 4361 Classroom Management Secondary" },
{ name: "ED 4363 Reading II/ELAR Methods" },
{ name: "ED 4372 Educational Technology" },
{ name: "ED 4693 Student Teaching - Elementary" },
{ name: "ED 4694 Student Teaching Secondary" },
{ name: "ED 4V90 Independent Study in Education" },
{ name: "ED 4V95 Special Topics in Education" },
{ name: "EE 1100 Introduction to Electrical and Computer Engineering" },
{ name: "EE 1202 Introduction to Electrical Engineering II" },
{ name: "EE 2310 Introduction to Digital Systems" },
{ name: "EE 3120 Digital Circuits Laboratory" },
{ name: "EE 3161 Social Issues and Ethics in Engineering" },
{ name: "EE 3201 Electrical and Computer Engineering Fundamentals-I Laboratory" },
{ name: "EE 3202 Electrical and Computer Engineering Fundamentals-II Laboratory" },
{ name: "EE 3301 Electrical Network Analysis" },
{ name: "EE 3302 Signals and Systems" },
{ name: "EE 3310 Electronic Devices" },
{ name: "EE 3311 Electronic Circuits" },
{ name: "EE 3320 Digital Circuits" },
{ name: "EE 3350 Communications Systems" },
{ name: "EE 4168 RF/Microwave Laboratory" },
{ name: "EE 4201 Electrical and Computer Engineering Laboratory in Computing Systems and Computer Engineering" },
{ name: "EE 4202 Electrical and Computer Engineering Laboratory in Circuits" },
{ name: "EE 4203 Electrical and Computer Engineering Laboratory in Signals and Systems" },
{ name: "EE 4204 Electrical and Computer Engineering Laboratory in Devices" },
{ name: "EE 4205 Electrical and Computer Engineering Laboratory in Power Electronics and Energy Systems" },
{ name: "EE 4301 Electromagnetic Engineering I" },
{ name: "EE 4304 Computer Architecture" },
{ name: "EE 4310 Systems and Controls" },
{ name: "EE 4325 Introduction to VLSI Design" },
{ name: "EE 4330 Integrated Circuit Technology" },
{ name: "EE 4331 Applied Machine Learning" },
{ name: "EE 4340 Analog Integrated Circuit Analysis and Design" },
{ name: "EE 4342 Introduction to Robotics" },
{ name: "EE 4360 Digital Communications" },
{ name: "EE 4361 Introduction to Digital Signal Processing" },
{ name: "EE 4362 Introduction to Energy Conversion" },
{ name: "EE 4363 Introduction to Power Electronics" },
{ name: "EE 4365 Introduction to Wireless Communication" },
{ name: "EE 4367 Telecommunication Networks" },
{ name: "EE 4368 RF Circuit Design Principles" },
{ name: "EE 4370 Embedded Systems" },
{ name: "EE 4371 Introduction to MEMS" },
{ name: "EE 4388 Senior Design Project I" },
{ name: "EE 4389 Senior Design Project II" },
{ name: "EE 4391 Technology of Plasma" },
{ name: "EE 4399 Senior Honors in Electrical Engineering" },
{ name: "EE 4V95 Undergraduate Topics in Electrical Engineering" },
{ name: "EE 4V97 Independent Study in Electrical Engineering" },
{ name: "EE 4V98 Undergraduate Research in Electrical Engineering" },
{ name: "ENGR 2300 Linear Algebra for Engineers" },
{ name: "ENGR 3300 Advanced Engineering Mathematics" },
{ name: "ENGR 3341 Probability Theory and Statistics" },
{ name: "ENGY 3300 Introduction to Energy Technology" },
{ name: "ENGY 3301 Managing Carbon Assets: Oil, Gas, and Coal" },
{ name: "ENGY 3302 Managing Power and Renewable Energy Assets  Sustainability" },
{ name: "ENGY 3330 Energy Economics" },
{ name: "ENGY 3340 Energy Law and Contracts" },
{ name: "ENGY 4300 Energy Land Management" },
{ name: "ENGY 4313 Energy Finance" },
{ name: "ENTP 3301 Innovation and Entrepreneurship" },
{ name: "ENTP 3320 Start-up Launch I" },
{ name: "ENTP 3321 Start-up Launch II" },
{ name: "ENTP 3360 Entrepreneurial Finance" },
{ name: "ENTP 4090 Innovation and Entrepreneurship Internship" },
{ name: "ENTP 4311 Entrepreneurial Strategy and Business Models" },
{ name: "ENTP 4320 Small Business Management" },
{ name: "ENTP 4330 Entrepreneurial Marketing" },
{ name: "ENTP 4331 Digital Prospecting" },
{ name: "ENTP 4335 Digital and Internet Marketing" },
{ name: "ENTP 4340 Social Sector Entrepreneurship and Community Engagement" },
{ name: "ENTP 4350 Corporate Entrepreneurship" },
{ name: "ENTP 4360 Innovation and Creativity" },
{ name: "ENTP 4395 Capstone Senior Project - Entrepreneurship" },
{ name: "ENTP 4V00 Special Topics in Entrepreneurship" },
{ name: "ENTP 4V90 Innovation and Entrepreneurship Internship" },
{ name: "ENVR 2302 The Global Environment" },
{ name: "EPCS 2100 Engineering Project in Community Service" },
{ name: "EPCS 2200 Engineering Projects in Community Service" },
{ name: "EPCS 3100 Engineering Project in Community Service II" },
{ name: "EPCS 3200 Engineering Projects in Community Service II" },
{ name: "EPPS 1110 Critical Issues in the Social Sciences" },
{ name: "EPPS 2301 Research Design in the Social and Policy Sciences" },
{ name: "EPPS 2302 Methods of Quantitative Analysis in the Social and Policy Sciences" },
{ name: "EPPS 2305 Spatial Thinking and Data Analytics" },
{ name: "EPPS 3301 Special Topics In Economic, Political and Policy Sciences" },
{ name: "EPPS 3310 Community-Based Service Learning" },
{ name: "FILM 1303 Transnational Film and Video" },
{ name: "FILM 2332 Understanding Film" },
{ name: "FILM 2V71 Independent Study in Film" },
{ name: "FILM 3321 Film in Historical Context" },
{ name: "FILM 3325 Film Authorship" },
{ name: "FILM 3342 Topics in Film" },
{ name: "FILM 4V71 Independent Study in Film" },
{ name: "FIN 3100 Professional Development" },
{ name: "FIN 3200 Introduction to Business and Professional Development" },
{ name: "FIN 3300 Personal Finance" },
{ name: "FIN 3305 Real Estate Principles" },
{ name: "FIN 3320 Business Finance" },
{ name: "FIN 3330 Personal Financial Planning" },
{ name: "FIN 3340 Regulation of Business and Financial Markets" },
{ name: "FIN 3350 Financial Markets and Institutions" },
{ name: "FIN 3360 Entrepreneurial Finance" },
{ name: "FIN 3365 Real Estate Finance and Principles" },
{ name: "FIN 3370 Principles of Risk Management and Insurance" },
{ name: "FIN 3375 Life, Accident and Health Insurance" },
{ name: "FIN 3380 International Financial Management" },
{ name: "FIN 3390 Introduction to Financial Modeling" },
{ name: "FIN 3395 Financial Modeling and Valuation" },
{ name: "FIN 4080 Practicum in Finance" },
{ name: "FIN 4300 Investment Management" },
{ name: "FIN 4303 Investment Strategies" },
{ name: "FIN 4305 Fixed Income Securities Analysis" },
{ name: "FIN 4307 Private Equity" },
{ name: "FIN 4310 Intermediate Financial Management" },
{ name: "FIN 4313 Energy Finance" },
{ name: "FIN 4315 Behavioral Economics and Finance" },
{ name: "FIN 4320 Management of Financial Institutions" },
{ name: "FIN 4321 Real Estate Law and Contracts" },
{ name: "FIN 4328 Real Estate Valuation" },
{ name: "FIN 4330 Estate Planning" },
{ name: "FIN 4331 Business Liability Risk Management and Insurance" },
{ name: "FIN 4332 Commercial Property Risk Management and Insurance" },
{ name: "FIN 4333 Enterprise Risk Management" },
{ name: "FIN 4334 Insurance Law and Contracts" },
{ name: "FIN 4335 Financial Aspects of Retirement and Employee Benefits" },
{ name: "FIN 4336 Risk Control and Safety Engineering" },
{ name: "FIN 4337 Business Valuation" },
{ name: "FIN 4338 Financial Decision Making Processes for Risk Management" },
{ name: "FIN 4340 Options and Futures Markets" },
{ name: "FIN 4345 Financial Information and Analysis" },
{ name: "FIN 4350 Cases in Personal Financial Planning" },
{ name: "FIN 4380 Domestic Fund Management" },
{ name: "FIN 4386 Beginning Mathematical Finance" },
{ name: "FIN 4387 Computational Methods in Finance" },
{ name: "FIN 4390 Seminar Series in Finance" },
{ name: "FIN 4395 Capstone Senior Project - Finance" },
{ name: "FIN 4399 Senior Honors in Finance" },
{ name: "FIN 4V80 Practicum in Finance" },
{ name: "FIN 4V90 Individual Study in Finance" },
{ name: "FIN 4V99 Special Topics in Finance" },
{ name: "FREN 1311 Beginning French I" },
{ name: "FREN 1312 Beginning French II" },
{ name: "FREN 2311 Intermediate French I" },
{ name: "FREN 2312 Intermediate French II" },
{ name: "GEOG 2302 The Global Environment" },
{ name: "GEOG 2303 People and Place: An Introduction to World Geographic Regions" },
{ name: "GEOG 3304 Principles of Geospatial Information Sciences" },
{ name: "GEOG 3331 Urban Growth and Structure" },
{ name: "GEOG 3357 Spatial Dimensions of Health and Disease" },
{ name: "GEOG 3359 Human Migration and Mobility: Global Patterns" },
{ name: "GEOG 3370 The Global Economy" },
{ name: "GEOG 3372 Population and Development" },
{ name: "GEOG 3377 Urban Planning and Policy" },
{ name: "GEOG 3382 Russia: Yesterday, Today, and Tomorrow" },
{ name: "GEOG 4309 Urban Development" },
{ name: "GEOG 4380 Spatial Concepts and Organization" },
{ name: "GEOG 4396 Selected Topics in Geography" },
{ name: "GEOG 4V97 Independent Study in Geography" },
{ name: "GEOS 1103 Physical Geology Laboratory" },
{ name: "GEOS 1104 History of Earth and Life Laboratory" },
{ name: "GEOS 1303 Physical Geology" },
{ name: "GEOS 1304 History of Earth and Life" },
{ name: "GEOS 2302 The Global Environment" },
{ name: "GEOS 2305 Spatial Thinking and Data Analytics" },
{ name: "GEOS 2306 Essentials of Field Geologic Methods" },
{ name: "GEOS 2310 Environmental Geology" },
{ name: "GEOS 2321 Geology, Resources, and Environment of Latin America" },
{ name: "GEOS 2324 Energy, the Environment and Human Health" },
{ name: "GEOS 2328 Geologic Time: An Historical Perspective" },
{ name: "GEOS 2332 Age of Dinosaurs" },
{ name: "GEOS 2333 Introduction to Fossils" },
{ name: "GEOS 2409 Rocks and Minerals" },
{ name: "GEOS 2V08 Special Topics in Geology or Geophysics I" },
{ name: "GEOS 3122 Coal in Our Society" },
{ name: "GEOS 3124 Energy, the Environment and Human Health" },
{ name: "GEOS 3300 Field Geology I" },
{ name: "GEOS 3304 Principles of Geospatial Information Sciences" },
{ name: "GEOS 3421 Stratigraphy and Sedimentology" },
{ name: "GEOS 3434 Paleobiology" },
{ name: "GEOS 3464 Igneous and Metamorphic Petrology" },
{ name: "GEOS 3470 Structural Geology" },
{ name: "GEOS 4300 Field Geology II" },
{ name: "GEOS 4320 The Physics and Chemistry of the Solid Earth" },
{ name: "GEOS 4322 The Earth System" },
{ name: "GEOS 4325 Introduction to Remote Sensing" },
{ name: "GEOS 4369 Volcanic Successions" },
{ name: "GEOS 4390 Communication in the Geosciences" },
{ name: "GEOS 4391 Geoscience Animations and Video" },
{ name: "GEOS 4399 Senior Honors in Geosciences" },
{ name: "GEOS 4430 Hydrogeology and Aqueous Geochemistry" },
{ name: "GEOS 4V08 Special Topics in Geology or Geophysics II" },
{ name: "GEOS 4V09 Senior Research in Geology" },
{ name: "GEOS 4V80 Senior Research in Geophysics" },
{ name: "GERM 1311 Beginning German I" },
{ name: "GERM 1312 Beginning German II" },
{ name: "GERM 2311 Intermediate German I" },
{ name: "GERM 2312 Intermediate German II" },
{ name: "GERM 2350 Business German" },
{ name: "GISC 2302 Geodesy and Geospatial Analysis" },
{ name: "GISC 2305 Spatial Thinking and Data Analytics" },
{ name: "GISC 3304 Principles of Geospatial Information Sciences" },
{ name: "GISC 4310 Environmental and Health Policy in East Asia" },
{ name: "GISC 4317 GeoComputation" },
{ name: "GISC 4325 Introduction to Remote Sensing" },
{ name: "GISC 4326 Cartography and GeoVisualization" },
{ name: "GISC 4363 Internet Mapping and Information Processing" },
{ name: "GISC 4380 Spatial Concepts and Organization" },
{ name: "GISC 4381 Spatial Data Science" },
{ name: "GISC 4382 Applied Geographic Information Systems" },
{ name: "GISC 4384 Health and Environmental GIS: A Global Perspective" },
{ name: "GISC 4385 Advanced Applications in GIS" },
{ name: "GISC 4386 Global Change and Its Challenges" },
{ name: "GISC 4V96 Special Topics in Geospatial Information Science" },
{ name: "GISC 4V97 Independent Study in Geospatial Information Science" },
{ name: "GISC 4V98 Internship" },
{ name: "GOVT 2107 Federal and Texas Constitutions" },
{ name: "GOVT 2305 American National Government" },
{ name: "GOVT 2306 State and Local Government" },
{ name: "GST 2300 Introduction to Gender Studies" },
{ name: "GST 2311 Social Change and Photography in America" },
{ name: "GST 2V90 Topics in Gender Studies" },
{ name: "GST 3301 Psychology of Gender" },
{ name: "GST 3302 Gender in Western Thought" },
{ name: "GST 3303 Gender, Society and Politics" },
{ name: "GST 4325 Motherhood and the Technological Womb" },
{ name: "GST 4360 Rebels and Reformers: Women and Alcohol in America" },
{ name: "GST 4379 Special Topics in Gender Studies" },
{ name: "GST 4380 Women, Work and Family" },
{ name: "GST 4381 Senior Honors Research" },
{ name: "GST 4382 Senior Honors in Gender Studies" },
{ name: "GST 4V80 Independent Study" },
{ name: "HIST 1301 U.S. History Survey to Civil War" },
{ name: "HIST 1302 U.S. History Survey from Civil War" },
{ name: "HIST 2301 History of Texas" },
{ name: "HIST 2330 Themes and Ideas in American History" },
{ name: "HIST 2340 Middle East Civilizations" },
{ name: "HIST 2341 East Asian Civilizations" },
{ name: "HIST 2350 Latin American Civilizations" },
{ name: "HIST 2370 The Holocaust" },
{ name: "HIST 2381 African-American History" },
{ name: "HIST 2384 U.S. Women from Settlement to Present" },
{ name: "HIST 2V71 Independent Study in Historical Studies" },
{ name: "HIST 3301 Historical Inquiry" },
{ name: "HIST 3302 Gender in Western Thought" },
{ name: "HIST 3305 War and Genocide in the 20th Century" },
{ name: "HIST 3306 History of Racism" },
{ name: "HIST 3312 Early China" },
{ name: "HIST 3313 Medieval China" },
{ name: "HIST 3314 Tang Dynasty China" },
{ name: "HIST 3319 Early Modern Europe" },
{ name: "HIST 3320 Modern Europe" },
{ name: "HIST 3324 Women in Modern European Society" },
{ name: "HIST 3327 Perspectives on Science" },
{ name: "HIST 3328 History and Philosophy of Science and Medicine" },
{ name: "HIST 3337 Technology and Western Civilization" },
{ name: "HIST 3340 US South through the Civil War" },
{ name: "HIST 3341 US South since the Civil War" },
{ name: "HIST 3344 History of Science in Europe" },
{ name: "HIST 3346 Medieval Islamic World" },
{ name: "HIST 3364 History of American Religion" },
{ name: "HIST 3365 The American West" },
{ name: "HIST 3366 Themes in the Social History of the United States" },
{ name: "HIST 3368 North American Environmental History" },
{ name: "HIST 3369 United States Foreign Relations" },
{ name: "HIST 3374 American Technological Development" },
{ name: "HIST 3376 American Intellectual History, Colonial to the Civil War" },
{ name: "HIST 3377 American Intellectual History, Civil War to the Present" },
{ name: "HIST 3379 United States Relations with Latin America" },
{ name: "HIST 3380 The Nuclear Age in America" },
{ name: "HIST 3382 The United States Since 1945" },
{ name: "HIST 3386 World History to 1500" },
{ name: "HIST 3387 World History from 1500" },
{ name: "HIST 3389 History of Science in the U.S." },
{ name: "HIST 3390 Twentieth Century African-American History" },
{ name: "HIST 3391 Modern Mexico" },
{ name: "HIST 3396 Native Americans in the Twentieth Century" },
{ name: "HIST 3397 Study Abroad in Latin America" },
{ name: "HIST 3398 Colonial Latin American History" },
{ name: "HIST 3399 Modern Latin American History" },
{ name: "HIST 4344 Topics in European History" },
{ name: "HIST 4347 Topics in Early Modern European History" },
{ name: "HIST 4349 Jewish History" },
{ name: "HIST 4357 Topics in African and African-American History" },
{ name: "HIST 4358 Topics in Asian History" },
{ name: "HIST 4359 Topics in Latin American History" },
{ name: "HIST 4360 Topics in American Women's History" },
{ name: "HIST 4369 Topics in Borderlands History" },
{ name: "HIST 4376 Topics in History" },
{ name: "HIST 4377 Topics in Early American History" },
{ name: "HIST 4378 Topics in American History" },
{ name: "HIST 4379 Topics in Women's and Gender History" },
{ name: "HIST 4380 Topics in Intellectual History" },
{ name: "HIST 4381 Topics in Comparative History" },
{ name: "HIST 4382 Topics in Middle Eastern History" },
{ name: "HIST 4383 Topics in U.S. Southern History" },
{ name: "HIST 4384 Topics in the History of Religion" },
{ name: "HIST 4385 Topics in the History of Science" },
{ name: "HIST 4386 Topics in the History of Race or Ethnicity" },
{ name: "HIST 4387 Topics in Urban History" },
{ name: "HIST 4388 Topics in Holocaust History" },
{ name: "HIST 4390 History Capstone Project" },
{ name: "HIST 4V71 Independent Study in Historical Studies" },
{ name: "HIST 4V99 Senior Honors in Historical Studies" },
{ name: "HLTH 1100 Career Explorations for the Health Professions" },
{ name: "HLTH 1301 Introduction to Kinesiology" },
{ name: "HLTH 1322 Human Nutrition" },
{ name: "HLTH 3101 Medical Terminology" },
{ name: "HLTH 3300 Pre-Health Professional Development" },
{ name: "HLTH 3301 Issues in Geriatric Healthcare" },
{ name: "HLTH 3305 The U.S. Healthcare System" },
{ name: "HLTH 3306 Gender in Healthcare" },
{ name: "HLTH 3310 Health Care Issues: Global Perspectives" },
{ name: "HLTH 3315 Issues in Patient Education" },
{ name: "HLTH 4108 Verbal Reasoning and Writing for Pre-Health" },
{ name: "HLTH 4304 Health Professions Internship" },
{ name: "HLTH 4305 Public Health" },
{ name: "HLTH 4306 Health and Sport" },
{ name: "HLTH 4307 Climate Change in Healthcare" },
{ name: "HLTH 4380 Special Topics in Healthcare" },
{ name: "HLTH 4V01 Health Professions Independent Study" },
{ name: "HMGT 3080 Healthcare Management Honors Program Seminar" },
{ name: "HMGT 3100 Professional Development" },
{ name: "HMGT 3200 Introduction to Business and Professional Development" },
{ name: "HMGT 3301 Introduction to Healthcare Management" },
{ name: "HMGT 3310 Healthcare Regulatory Environment" },
{ name: "HMGT 3311 Healthcare Accounting" },
{ name: "HMGT 3320 Complex and Dynamic Healthcare Environment" },
{ name: "HMGT 4090 Healthcare Management Internship" },
{ name: "HMGT 4321 Introduction to Healthcare Information Systems" },
{ name: "HMGT 4331 Marketing in Healthcare Organizations" },
{ name: "HMGT 4351 Management, Design and Optimization of Healthcare Processes" },
{ name: "HMGT 4392 International Healthcare Management and Leadership" },
{ name: "HMGT 4395 Capstone Senior Project - Healthcare Management" },
{ name: "HMGT 4V90 Healthcare Management Internship" },
{ name: "HONS 3101 Medicine, Politics, and Philosophy" },
{ name: "HONS 3104 Foreign Film & Political Culture" },
{ name: "HONS 3106 Positive Psychology" },
{ name: "HONS 3108 Internet and Public Policy" },
{ name: "HONS 3110 The Addicted Brain" },
{ name: "HONS 3111 Science Fiction" },
{ name: "HONS 3112 Contemporary Topics in Business" },
{ name: "HONS 3113 Lives of the Genus Genius" },
{ name: "HONS 3114 Scientific Research Concepts" },
{ name: "HONS 3115 Exploring Research at UT Dallas" },
{ name: "HONS 3199 Collegium V Honors Readings" },
{ name: "HONS 4V96 Collegium V Honors Capstone" },
{ name: "HONS 4V97 Independent Study in Honors" },
{ name: "HUMA 1301 Exploration of the Humanities" },
{ name: "HUMA 3342 Topics in the Humanities" },
{ name: "HUMA 3350 Introduction to Islam" },
{ name: "HUMA 3351 Introduction to Islamic Culture" },
{ name: "HUMA 4V71 Independent Study in the Humanities" },
{ name: "IMS 3091 Regional Management Area Studies: Latin America" },
{ name: "IMS 3092 Regional Management Area Studies: Western Europe" },
{ name: "IMS 3093 Regional Management Area Studies: Asia" },
{ name: "IMS 3094 Regional Management Area Studies: Africa" },
{ name: "IMS 3095 Regional Management Area Studies: North America" },
{ name: "IMS 3096 Regional Management Area Studies: Eastern Europe" },
{ name: "IMS 3100 Professional Development" },
{ name: "IMS 3310 International Business" },
{ name: "IMS 3V91 Regional Management Area Studies: Latin America" },
{ name: "IMS 3V92 Regional Management Area Studies: Western Europe" },
{ name: "IMS 3V93 Regional Management Area Studies: Asia" },
{ name: "IMS 3V94 Regional Management Area Studies: Africa" },
{ name: "IMS 3V95 Regional Management Area Studies: North America" },
{ name: "IMS 3V96 Regional Management Area Studies: Eastern Europe" },
{ name: "IMS 4090 Management Internship" },
{ name: "IMS 4091 International Social Sector Engagement and Community Outreach Practicum" },
{ name: "IMS 4095 International Social Sector Engagement and Community Outreach Practicum" },
{ name: "IMS 4310 Export Market Development" },
{ name: "IMS 4320 International Marketing" },
{ name: "IMS 4325 International Advertising and Promotion" },
{ name: "IMS 4330 Global Human Resource Management" },
{ name: "IMS 4332 International Negotiation" },
{ name: "IMS 4334 International Leadership" },
{ name: "IMS 4335 Social Sector Entrepreneurship and Community Engagement" },
{ name: "IMS 4373 Global Strategy" },
{ name: "IMS 4395 Capstone Senior Project - Global Business" },
{ name: "IMS 4V81 Individual Study in International Management" },
{ name: "IMS 4V90 Management Internship" },
{ name: "IMS 4V94 Seminar Series in International Management" },
{ name: "IPEC 3349 World Resources and Development" },
{ name: "IPEC 4301 Political Economy of Industrialized Countries" },
{ name: "IPEC 4302 Political Economy of Developing Countries" },
{ name: "IPEC 4303 Political Economy of South and Southeast Asia" },
{ name: "IPEC 4304 Political Economy of Latin America" },
{ name: "IPEC 4305 Topics in Science, Technology and Institutions" },
{ name: "IPEC 4306 Economic Development: Re-Engineering Places and Performances" },
{ name: "IPEC 4307 Regional Topics in International Political Economy" },
{ name: "IPEC 4308 Political Economy of Africa" },
{ name: "IPEC 4309 Urban Development" },
{ name: "IPEC 4310 Environmental and Health Policy in East Asia" },
{ name: "IPEC 4311 Regional Topics in International Political Economy" },
{ name: "IPEC 4312 The Intangible Economy: Exploring 21st-Century New Growth Frontiers" },
{ name: "IPEC 4375 Benefit-Cost Analysis" },
{ name: "IPEC 4376 Foreign Policy and Public Opinion" },
{ name: "IPEC 4377 Politics of International Finance" },
{ name: "IPEC 4384 Health and Environmental Policy: A Global Perspective" },
{ name: "IPEC 4396 Topics in International Political Economy" },
{ name: "IPEC 4V91 Undergraduate Research in International Political Economy" },
{ name: "IPEC 4V97 Independent Study" },
{ name: "IPEC 4V98 Internship" },
{ name: "IPEC 4V99 Senior Honors in International Political Economy" },
{ name: "ISAE 2121 Careers for ATEC Majors" },
{ name: "ISAE 4V50 Internship" },
{ name: "ISAH 2130 Careers for Arts and Humanities Majors" },
{ name: "ISAH 2188 Enrichment in the Arts and Humanities" },
{ name: "ISAH 4V50 Internship" },
{ name: "ISAH 4V88 Special Interdisciplinary Topics in the Arts and Humanities" },
{ name: "ISEC 4102 Computer Art Laboratory" },
{ name: "ISEC 4201 The Computer and the Artist" },
{ name: "ISEC 4395 Computing in Society" },
{ name: "ISEC 4V87 Special Interdisciplinary Topics in Engineering or Computer Science" },
{ name: "ISIS 3309 Dental Anthropology" },
{ name: "ISIS 3310 Childhood Sexual Abuse: A Multidisciplinary Investigation" },
{ name: "ISIS 3312 Women in Management" },
{ name: "ISIS 3334 Environment in America" },
{ name: "ISIS 3335 United States and East Asia" },
{ name: "ISIS 3V70 Teaching Internship" },
{ name: "ISIS 4303 Business, Law and Culture" },
{ name: "ISIS 4304 Trials in American Culture" },
{ name: "ISIS 4309 Diversity and Globalization" },
{ name: "ISIS 4310 Health Strategy for Multicultural Populations" },
{ name: "ISIS 4350 International Development: Cultural Impacts" },
{ name: "ISIS 4V89 Special Topics in Interdisciplinary Studies" },
{ name: "ISNS 2359 Earthquakes and Volcanoes" },
{ name: "ISNS 2366 Global Climate Change" },
{ name: "ISNS 2367 The Oceans" },
{ name: "ISNS 2368 Weather and Climate" },
{ name: "ISNS 3371 The Phenomena of Nature: Forces, Gases, Motion, Heat, Light and Electricity" },
{ name: "ISNS 3373 Our Nearest Neighbors in the Sky" },
{ name: "ITSS 3100 Professional Development" },
{ name: "ITSS 3200 Introduction to Business and Professional Development" },
{ name: "ITSS 3300 Information Technology for Business" },
{ name: "ITSS 3311 Introduction to Programming" },
{ name: "ITSS 3312 Object-Oriented Programming" },
{ name: "ITSS 3390 Web Design and Development for Business Applications" },
{ name: "ITSS 4090 Information Technology and Systems Internship" },
{ name: "ITSS 4300 Database Fundamentals" },
{ name: "ITSS 4301 Database Systems" },
{ name: "ITSS 4312 Mobile Web Application Development" },
{ name: "ITSS 4320 Introduction to Healthcare Information Systems" },
{ name: "ITSS 4330 Systems Analysis and Design" },
{ name: "ITSS 4340 Enterprise Resource Planning" },
{ name: "ITSS 4342 Analysis and Design of Accounting Systems" },
{ name: "ITSS 4343 Integrated SCM Information Systems" },
{ name: "ITSS 4351 Foundations of Business Intelligence" },
{ name: "ITSS 4352 Introduction to Web Analytics" },
{ name: "ITSS 4353 Business Analytics" },
{ name: "ITSS 4354 Advanced Big Data Analytics" },
{ name: "ITSS 4355 Data Visualization" },
{ name: "ITSS 4360 Network and Information Security" },
{ name: "ITSS 4361 Information Technology Cybersecurity" },
{ name: "ITSS 4362 Cybersecurity Governance" },
{ name: "ITSS 4370 Information Technology Infrastructure" },
{ name: "ITSS 4380 Advanced Database Management" },
{ name: "ITSS 4381 Object Oriented Programming with Python" },
{ name: "ITSS 4395 Capstone Senior Project - Information Systems" },
{ name: "ITSS 4V81 Individual Study in ITSS" },
{ name: "ITSS 4V90 ITSS Internship" },
{ name: "ITSS 4V95 Seminar Series in Information Systems" },
{ name: "JAPN 1311 Beginning Japanese I" },
{ name: "JAPN 1312 Beginning Japanese II" },
{ name: "JAPN 2311 Intermediate Japanese I" },
{ name: "JAPN 2312 Intermediate Japanese II" },
{ name: "JAPN 2350 Business Japanese" },
{ name: "JAPN 3311 Advanced Japanese I" },
{ name: "JAPN 3312 Advanced Japanese II" },
{ name: "JAPN 4301 Advanced Japanese Conversation" },
{ name: "JAPN 4364 Advanced Japanese Culture" },
{ name: "LANG 1311 Beginning Language Instruction I" },
{ name: "LANG 1312 Beginning Language Instruction II" },
{ name: "LANG 2312 Intermediate Language Instruction II" },
{ name: "LANG 2V42 Topics in Language" },
{ name: "LANG 2V71 Independent Study in Language" },
{ name: "LANG 3342 Advanced Language Instruction" },
{ name: "LANG 3348 Topics in Language" },
{ name: "LANG 4348 Advanced Topics in Language" },
{ name: "LANG 4V71 Independent Study in Language" },
{ name: "LATS 2V71 Independent Study in Latin American Studies" },
{ name: "LATS 3301 Latin American Literature" },
{ name: "LATS 3302 Latin American Film" },
{ name: "LATS 3303 Latin Social Dance" },
{ name: "LATS 4V71 Independent Study in Latin American Studies" },
{ name: "LATS 4V99 Senior Honors in Latin American Studies" },
{ name: "LIT 1301 Introduction to Literature" },
{ name: "LIT 2320 Survey A: Literature in English" },
{ name: "LIT 2321 Survey B: Literature in Translation" },
{ name: "LIT 2329 Introduction to Ethnic American Literature" },
{ name: "LIT 2331 Introduction to World Literature" },
{ name: "LIT 2350 Close Reading" },
{ name: "LIT 2V71 Independent Study in Literature" },
{ name: "LIT 3300 Western Literary Tradition" },
{ name: "LIT 3309 Studies in the Short Story" },
{ name: "LIT 3310 Studies in Epic and Romance" },
{ name: "LIT 3312 Studies in Prose Narrative" },
{ name: "LIT 3314 Studies in Poetry" },
{ name: "LIT 3316 The Literature of Science Fiction" },
{ name: "LIT 3317 The Literature of Fantasy" },
{ name: "LIT 3319 Contexts" },
{ name: "LIT 3320 Shakespeare" },
{ name: "LIT 3328 Ethics in Literature" },
{ name: "LIT 3330 Linguistics" },
{ name: "LIT 3332 English Syntax and Mechanical Structure" },
{ name: "LIT 3334 Literature of Science" },
{ name: "LIT 3337 Comparisons" },
{ name: "LIT 3338 Literary Theory and Aesthetic Philosophy" },
{ name: "LIT 3339 Writing Literary Studies" },
{ name: "LIT 4329 Single Author" },
{ name: "LIT 4348 Topics in Literature" },
{ name: "LIT 4390 Capstone" },
{ name: "LIT 4V71 Independent Study in Literature" },
{ name: "LIT 4V99 Senior Honors in Literature" },
{ name: "MATH 1306 College Algebra for the Non-Scientist" },
{ name: "MATH 1314 College Algebra" },
{ name: "MATH 1316 Trigonometry" },
{ name: "MATH 1325 Applied Calculus I" },
{ name: "MATH 1326 Applied Calculus II" },
{ name: "MATH 2306 Analytic Geometry" },
{ name: "MATH 2312 Precalculus" },
{ name: "MATH 2333 Matrices, Vectors, and Their Application" },
{ name: "MATH 2370 Introduction to Programming with MATLAB" },
{ name: "MATH 2399 Research and Advanced Writing" },
{ name: "MATH 2413 Differential Calculus" },
{ name: "MATH 2414 Integral Calculus" },
{ name: "MATH 2415 Calculus of Several Variables" },
{ name: "MATH 2417 Calculus I" },
{ name: "MATH 2418 Linear Algebra" },
{ name: "MATH 2419 Calculus II" },
{ name: "MATH 2420 Differential Equations with Applications" },
{ name: "MATH 2451 Multivariable Calculus with Applications" },
{ name: "MATH 2V90 Topics in Mathematics - Level 2" },
{ name: "MATH 3301 Mathematics for Elementary and Middle School Teachers" },
{ name: "MATH 3303 Introduction to Mathematical Modeling" },
{ name: "MATH 3305 Foundations of Measurement and Informal Geometry" },
{ name: "MATH 3307 Mathematical Problem Solving for Teachers" },
{ name: "MATH 3310 Theoretical Concepts of Calculus" },
{ name: "MATH 3311 Abstract Algebra I" },
{ name: "MATH 3312 Abstract Algebra II" },
{ name: "MATH 3315 Discrete Mathematics and Combinatorics" },
{ name: "MATH 3321 Geometry" },
{ name: "MATH 3323 Elementary Number Theory" },
{ name: "MATH 3351 Advanced Calculus" },
{ name: "MATH 3379 Complex Variables" },
{ name: "MATH 3380 Differential Geometry" },
{ name: "MATH 3397 Mathematical Problem Solving" },
{ name: "MATH 4301 Mathematical Analysis I" },
{ name: "MATH 4302 Mathematical Analysis II" },
{ name: "MATH 4332 Scientific Math Computing" },
{ name: "MATH 4334 Numerical Analysis" },
{ name: "MATH 4341 Topology" },
{ name: "MATH 4355 Methods of Applied Mathematics" },
{ name: "MATH 4362 Partial Differential Equations" },
{ name: "MATH 4381 Structure of Modern Geometry" },
{ name: "MATH 4390 Senior Research and Advanced Writing" },
{ name: "MATH 4399 Senior Honors in Mathematics" },
{ name: "MATH 4475 Capstone Project" },
{ name: "MATH 4V03 Independent Study in Mathematics" },
{ name: "MATH 4V91 Undergraduate Topics in Mathematics" },
{ name: "MECH 1100 Introduction to Mechanical Engineering I" },
{ name: "MECH 1208 Introduction to Mechanical Engineering II" },
{ name: "MECH 1V95 Topics in Mechanical Engineering" },
{ name: "MECH 2120 Mechanics of Materials Laboratory" },
{ name: "MECH 2310 Statics" },
{ name: "MECH 2320 Mechanics of Materials" },
{ name: "MECH 2330 Dynamics" },
{ name: "MECH 2340 Circuits and Applied Electronics" },
{ name: "MECH 2V95 Topics in Mechanical Engineering" },
{ name: "MECH 3105 Computer Aided Design Laboratory" },
{ name: "MECH 3115 Fluid Mechanics Laboratory" },
{ name: "MECH 3120 Heat Transfer Laboratory" },
{ name: "MECH 3150 Kinematics and Dynamics Laboratory" },
{ name: "MECH 3305 Computer Aided Design" },
{ name: "MECH 3310 Thermodynamics" },
{ name: "MECH 3315 Fluid Mechanics" },
{ name: "MECH 3320 Heat Transfer" },
{ name: "MECH 3340 System Dynamics Modeling and Analysis" },
{ name: "MECH 3350 Kinematics and Dynamics of Mechanical Systems" },
{ name: "MECH 3351 Design of Mechanical Systems" },
{ name: "MECH 3360 Introduction to Materials Science" },
{ name: "MECH 3370 Applied Thermodynamics" },
{ name: "MECH 3380 Introduction to Computational Design and Analysis" },
{ name: "MECH 3381 Introduction to Manufacturing Processes" },
{ name: "MECH 3V95 Topics in Mechanical Engineering" },
{ name: "MECH 4110 Systems and Controls Laboratory" },
{ name: "MECH 4301 Intermediate Mechanics of Materials" },
{ name: "MECH 4310 Systems and Controls" },
{ name: "MECH 4320 Applications of Computational Tools in Thermal Fluid Science" },
{ name: "MECH 4330 Intermediate Fluid Mechanics" },
{ name: "MECH 4340 Mechanical Vibrations" },
{ name: "MECH 4342 Introduction to Robotics" },
{ name: "MECH 4360 Introduction to Nanostructured Materials" },
{ name: "MECH 4370 Introduction to MEMS" },
{ name: "MECH 4380 HVAC Systems" },
{ name: "MECH 4381 Senior Design Project I" },
{ name: "MECH 4382 Senior Design Project II" },
{ name: "MECH 4399 Senior Honors in Mechanical Engineering" },
{ name: "MECH 4V95 Topics in Mechanical Engineering" },
{ name: "MECH 4V96 Individual Instruction in Mechanical Engineering" },
{ name: "MECH 4V98 Undergraduate Research in Mechanical Engineering" },
{ name: "MECO 3300 Introduction to Energy Technology" },
{ name: "MECO 3330 Energy Economics" },
{ name: "MECO 3340 Energy Law and Contracts" },
{ name: "MECO 3360 Comparative Institutions of Production and Distribution" },
{ name: "MECO 3361 Comparative Institutions of Production and Distribution II" },
{ name: "MECO 4300 Energy Land Management" },
{ name: "MECO 4315 Behavioral Economics and Finance" },
{ name: "MECO 4340 Thinking Strategically" },
{ name: "MECO 4342 Financial and Business Negotiation Analysis" },
{ name: "MECO 4351 Industrial Organization and Corporate Strategy" },
{ name: "MECO 4352 Applied Econometrics and Time Series Analysis" },
{ name: "MECO 4370 Data Science Decision Making" },
{ name: "MECO 4V90 Special Topics in Managerial Economics" },
{ name: "MKT 3100 Professional Development" },
{ name: "MKT 3200 Introduction to Business and Professional Development" },
{ name: "MKT 3300 Principles of Marketing" },
{ name: "MKT 3320 Product and Brand Management" },
{ name: "MKT 3330 Introduction to Professional Selling" },
{ name: "MKT 3331 Principles of Category Management" },
{ name: "MKT 3340 Marketing Research" },
{ name: "MKT 3V92 European Marketing Studies" },
{ name: "MKT 4090 Marketing Internship" },
{ name: "MKT 4320 International Marketing" },
{ name: "MKT 4330 Digital and Internet Marketing" },
{ name: "MKT 4331 Digital Prospecting" },
{ name: "MKT 4332 Advanced Personal Selling" },
{ name: "MKT 4333 Retailing and Distribution" },
{ name: "MKT 4334 Social Media Marketing" },
{ name: "MKT 4335 Category Buying" },
{ name: "MKT 4336 E-Retailing" },
{ name: "MKT 4337 Marketing Analytics" },
{ name: "MKT 4338 Marketing Content Creation" },
{ name: "MKT 4339 Marketing Creative Skills and Testing" },
{ name: "MKT 4340 Consumer Behavior" },
{ name: "MKT 4341 Predictive Analytics" },
{ name: "MKT 4350 Advertising" },
{ name: "MKT 4360 Social Marketing" },
{ name: "MKT 4370 Marketing Projects" },
{ name: "MKT 4390 Advanced Marketing Analytics" },
{ name: "MKT 4395 Capstone Senior Project - Marketing" },
{ name: "MKT 4V83 Individual Study in Marketing" },
{ name: "MKT 4V90 Marketing Internship" },
{ name: "MKT 4V93 Seminar Series in Marketing" },
{ name: "MSEN 3301 Introduction to Nanoscience and Nanotechnology" },
{ name: "MSEN 3302 Microscopy, Spectroscopy, and Nanotech Instrumentation" },
{ name: "MSEN 3304 Materials Science for Sustainable Energy" },
{ name: "MSEN 3310 Introduction to Materials Science" },
{ name: "MSEN 4391 Technology of Plasma" },
{ name: "MSEN 4V95 Undergraduate Research" },
{ name: "MUSI 1306 Understanding Music" },
{ name: "MUSI 1313 Fundamentals of Music" },
{ name: "MUSI 2112 Guitar Ensemble I" },
{ name: "MUSI 2113 Pep Band" },
{ name: "MUSI 2120 Wind Ensemble I" },
{ name: "MUSI 2124 Instrumental Ensemble I" },
{ name: "MUSI 2126 Jazz Ensemble I" },
{ name: "MUSI 2127 University Choir" },
{ name: "MUSI 2128 String Orchestra I" },
{ name: "MUSI 2129 Lower-Division Applied Music" },
{ name: "MUSI 2311 Topics in Music" },
{ name: "MUSI 2315 Guitar I" },
{ name: "MUSI 2317 Piano I" },
{ name: "MUSI 2319 Digital Music I" },
{ name: "MUSI 2321 Music Cultures of the World" },
{ name: "MUSI 2322 Music in Western Civilization" },
{ name: "MUSI 2325 Vocal Instruction I" },
{ name: "MUSI 2328 Music Theory I" },
{ name: "MUSI 2329 Music Theory II" },
{ name: "MUSI 2330 Music Theory III" },
{ name: "MUSI 2V71 Independent Study in Music" },
{ name: "MUSI 3112 Advanced Orchestra/Chamber Music Ensemble I" },
{ name: "MUSI 3116 Guitar Ensemble II" },
{ name: "MUSI 3118 String Orchestra II" },
{ name: "MUSI 3120 Wind Ensemble II" },
{ name: "MUSI 3122 Brass Ensemble I" },
{ name: "MUSI 3130 Percussion Ensemble" },
{ name: "MUSI 3131 Drumline" },
{ name: "MUSI 3181 Instrumental Ensemble II" },
{ name: "MUSI 3185 Chamber Singers I" },
{ name: "MUSI 3186 Jazz Ensemble II" },
{ name: "MUSI 3229 Upper-Division Applied Music" },
{ name: "MUSI 3322 Music in Historical Context" },
{ name: "MUSI 3324 Jazz History: Roots to Swing" },
{ name: "MUSI 3325 Jazz History: Modern Jazz since BeBop" },
{ name: "MUSI 3327 Music in Modern Culture" },
{ name: "MUSI 3328 Advanced Theory and Keyboard Skills" },
{ name: "MUSI 3330 Music Literature: Romantic to Modern" },
{ name: "MUSI 3342 Topics in Music" },
{ name: "MUSI 3380 Guitar II" },
{ name: "MUSI 3382 Vocal Instruction II" },
{ name: "MUSI 3384 Best of Broadway" },
{ name: "MUSI 3387 Keyboard Skills for Contemporary Music" },
{ name: "MUSI 3388 Piano II" },
{ name: "MUSI 3389 Digital Music II" },
{ name: "MUSI 4112 Advanced Orchestra/Chamber Music Ensemble II" },
{ name: "MUSI 4116 Guitar Ensemble III" },
{ name: "MUSI 4118 String Orchestra III" },
{ name: "MUSI 4120 Wind Ensemble III" },
{ name: "MUSI 4146 Instrumental Ensemble III" },
{ name: "MUSI 4147 Vocal Ensemble III" },
{ name: "MUSI 4185 Chamber Singers II" },
{ name: "MUSI 4186 Jazz Ensemble III" },
{ name: "MUSI 4322 Vocal Instruction III" },
{ name: "MUSI 4345 Music Performance III" },
{ name: "MUSI 4348 Creating Music" },
{ name: "MUSI 4390 Senior Project in Music" },
{ name: "MUSI 4V12 Chamber Music" },
{ name: "MUSI 4V13 Chamber Music II" },
{ name: "MUSI 4V61 Individual Instruction in Vocal Performance" },
{ name: "MUSI 4V71 Independent Study in Music" },
{ name: "NATS 1101 Natural Sciences and Mathematics Freshman Seminar" },
{ name: "NATS 1141 UTeach STEP 1" },
{ name: "NATS 1142 UTeach STEP 1" },
{ name: "NATS 1143 UTeach STEP 2" },
{ name: "NATS 1311 The Universe, and Everything Else" },
{ name: "NATS 2330 The Basis of Evolution" },
{ name: "NATS 2333 Energy, Water, and the Environment" },
{ name: "NATS 2V10 Special Topics in Natural Sciences" },
{ name: "NATS 3341 Knowing and Learning in Mathematics and Science" },
{ name: "NATS 3343 Classroom Interactions" },
{ name: "NATS 4141 UTeach Apprentice Teaching Seminar" },
{ name: "NATS 4310 Advanced Writing in the Natural Sciences and Mathematics" },
{ name: "NATS 4341 Project-Based Instruction" },
{ name: "NATS 4390 Research Methods" },
{ name: "NATS 4694 UTeach Apprentice Teaching, 7-12 Science and Mathematics" },
{ name: "NATS 4696 UTeach Apprentice Teaching, 4-8 Science and Mathematics" },
{ name: "NATS 4V41 Independent Study in Science and Math Education" },
{ name: "NATS 4V90 Special Topics in Natural Sciences" },
{ name: "NATS 4V91 Green Fellowship Directed Research" },
{ name: "NSC 3344 Anatomy and Physiology of Speech and Hearing" },
{ name: "NSC 3361 Introduction to Neuroscience" },
{ name: "NSC 4193 Internship Preparation" },
{ name: "NSC 4350 Medical Neuropathology" },
{ name: "NSC 4351 Medical Neuroscience" },
{ name: "NSC 4352 Cellular Neuroscience" },
{ name: "NSC 4353 Neuroscience Laboratory Methods" },
{ name: "NSC 4354 Integrative Neuroscience" },
{ name: "NSC 4355 Advanced Neuroscience Laboratory" },
{ name: "NSC 4356 Neurophysiology" },
{ name: "NSC 4357 Neurobiology of Learning and Memory" },
{ name: "NSC 4358 Neuroscience of Pain" },
{ name: "NSC 4359 Cognitive Neuroscience" },
{ name: "NSC 4360 Introduction to Entrepreneurship in Neuroscience" },
{ name: "NSC 4361 Case Studies in Therapeutic Discovery in Neuroscience" },
{ name: "NSC 4362 Molecular Neuroscience" },
{ name: "NSC 4363 Neuropharmacology" },
{ name: "NSC 4364 Journey into Medicine" },
{ name: "NSC 4365 Laboratory Methods in Human Neuroscience" },
{ name: "NSC 4366 Neuroanatomy" },
{ name: "NSC 4367 Developmental Neurobiology" },
{ name: "NSC 4370 Neuroendocrinology" },
{ name: "NSC 4371 Neural Plasticity" },
{ name: "NSC 4372 Neuroimmunology" },
{ name: "NSC 4373 Sensory Neuroscience" },
{ name: "NSC 4374 Neuroplasticity in Disorders of the Nervous System" },
{ name: "NSC 4376 Neurobiology of Stress" },
{ name: "NSC 4378 Neurotoxicology" },
{ name: "NSC 4382 Neurobiology of Emotion" },
{ name: "NSC 4383 Human Neurophysiology Lab" },
{ name: "NSC 4385 Neuropsychology" },
{ name: "NSC 4387 Neuropathology" },
{ name: "NSC 4388 Medical Physiology" },
{ name: "NSC 4389 Developmental Cognitive Neuroscience" },
{ name: "NSC 4391 Writing and Independent Study" },
{ name: "NSC 4394 Internship in Neuroscience" },
{ name: "NSC 4395 Internship in Neuroscience II" },
{ name: "NSC 4397 Thesis Research" },
{ name: "NSC 4V75 Honors Seminar" },
{ name: "NSC 4V90 Special Topics in Neuroscience" },
{ name: "NSC 4V91 Green Fellowship Directed Research" },
{ name: "NSC 4V95 Externship in Neuroscience" },
{ name: "NSC 4V96 Teaching Internship" },
{ name: "NSC 4V98 Directed Research" },
{ name: "NSC 4V99 Independent Study" },
{ name: "OBHR 3310 Organizational Behavior" },
{ name: "OBHR 3311 Principles of Management" },
{ name: "OBHR 3320 Groups and Teams" },
{ name: "OBHR 3330 Introduction to Human Resource Management" },
{ name: "OBHR 4090 Management Internship" },
{ name: "OBHR 4300 Management of Non-Profit Organizations" },
{ name: "OBHR 4310 Business Ethics" },
{ name: "OBHR 4331 Compensation and Benefits Administration" },
{ name: "OBHR 4333 Performance Management" },
{ name: "OBHR 4334 Talent Acquisition and Management" },
{ name: "OBHR 4335 Training and Development" },
{ name: "OBHR 4336 Labor and Employee Relations" },
{ name: "OBHR 4337 HR Analytics" },
{ name: "OBHR 4338 Managing Diversity in Organizations" },
{ name: "OBHR 4350 Introduction to Leading and Managing" },
{ name: "OBHR 4352 Negotiation and Dispute Resolution" },
{ name: "OBHR 4354 Leading Organizational Change" },
{ name: "OBHR 4356 Power and Influence in Organizations" },
{ name: "OBHR 4358 Transformational Leadership, Ethics, and Social Responsibility in Practice" },
{ name: "OBHR 4361 The Human Resource Professional" },
{ name: "OBHR 4370 Data Science Decision Making" },
{ name: "OBHR 4395 Capstone Senior Project - Human Resource Management" },
{ name: "OBHR 4V84 Individual Study" },
{ name: "OBHR 4V90 Management Internship" },
{ name: "OBHR 4V94 Seminar Series in Management" },
{ name: "OPRE 3100 Professional Development" },
{ name: "OPRE 3200 Introduction to Business and Professional Development" },
{ name: "OPRE 3310 Operations Management" },
{ name: "OPRE 3311 Introduction to Programming" },
{ name: "OPRE 3312 Object-Oriented Programming" },
{ name: "OPRE 3320 Integrated Supply Chain Management" },
{ name: "OPRE 3330 Project Management" },
{ name: "OPRE 3333 Quantitative Business Analysis" },
{ name: "OPRE 3340 Advanced Business Quantitative Methods" },
{ name: "OPRE 3360 Managerial Methods in Decision Making Under Uncertainty" },
{ name: "OPRE 4090 Supply Chain Management Internship" },
{ name: "OPRE 4310 Lean and Six Sigma Processes" },
{ name: "OPRE 4320 Integrated SCM Information Systems" },
{ name: "OPRE 4330 Global Logistics and Inventory Management" },
{ name: "OPRE 4337 Marketing Analytics" },
{ name: "OPRE 4340 Purchasing, Sourcing, and Contract Management" },
{ name: "OPRE 4345 Retail Operations" },
{ name: "OPRE 4350 Spreadsheet Modeling and Analytics" },
{ name: "OPRE 4353 Business Analytics" },
{ name: "OPRE 4395 Capstone Senior Project - Supply Chain Management" },
{ name: "OPRE 4V81 Individual Study in Supply Chain Management" },
{ name: "OPRE 4V90 Supply Chain Management Internship" },
{ name: "OPRE 4V91 Seminar Series in Supply Chain Management" },
{ name: "PA 2325 Introduction to Public and Nonprofit Management" },
{ name: "PA 3306 Advanced Research and Writing for the Policy Sciences" },
{ name: "PA 3310 Managing Government Organizations" },
{ name: "PA 3314 Financial Management" },
{ name: "PA 3333 Human Resources Management: Leading a Diverse Workforce" },
{ name: "PA 3377 Urban Planning and Policy" },
{ name: "PA 3378 Public Finance and Economics" },
{ name: "PA 3379 Diversity in the Public Sector" },
{ name: "PA 3380 Organizations: Theory and Behavior" },
{ name: "PA 3381 Field Research Methods" },
{ name: "PA 3382 Sustainable Communities" },
{ name: "PA 4317 Field Research Practicum" },
{ name: "PA 4340 Creating High Performance Organizations" },
{ name: "PA 4345 Negotiation and Conflict Resolution" },
{ name: "PA 4350 Public Agencies, Management and Ethics" },
{ name: "PA 4351 Managing the Urban Environment" },
{ name: "PA 4352 Emerging Communication Strategies in Public Service Organizations" },
{ name: "PA 4355 Managing Nonprofit Organizations" },
{ name: "PA 4370 Leadership, Democracy, and Ethics" },
{ name: "PA 4386 Health and Social Policy" },
{ name: "PA 4387 Project Management" },
{ name: "PA 4390 Capstone - Social Entrepreneurship: Leading Change" },
{ name: "PA 4396 Topics in Public Administration" },
{ name: "PA 4V97 Independent Study in Public Administration" },
{ name: "PA 4V98 Internship" },
{ name: "PA 4V99 Senior Honors in Public Administration" },
{ name: "PHIL 1301 Introduction to Philosophy" },
{ name: "PHIL 1305 Introduction to Philosophy of Religion" },
{ name: "PHIL 1306 Introduction to Ethics" },
{ name: "PHIL 2303 Introduction to Logic" },
{ name: "PHIL 2304 Understanding Scientific Inquiry" },
{ name: "PHIL 2316 History of Philosophy I" },
{ name: "PHIL 2317 History of Philosophy II" },
{ name: "PHIL 3309 Philosophy of Technology" },
{ name: "PHIL 3320 Medical Ethics" },
{ name: "PHIL 3321 Philosophical Traditions I" },
{ name: "PHIL 3322 Ancient Philosophy" },
{ name: "PHIL 3323 Early Modern Philosophy" },
{ name: "PHIL 3324 19th and 20th Century Philosophy" },
{ name: "PHIL 3328 History and Philosophy of Science and Medicine" },
{ name: "PHIL 3338 Literary Theory and Aesthetic Philosophy" },
{ name: "PHIL 3357 Confronting Death" },
{ name: "PHIL 3373 Philosophy of Mind" },
{ name: "PHIL 3375 Contemporary Ethical Issues" },
{ name: "PHIL 4308 Theories of Knowledge" },
{ name: "PHIL 4321 Philosophy of Medicine" },
{ name: "PHIL 4322 Philosophical Traditions II" },
{ name: "PHIL 4323 Ethical Theories" },
{ name: "PHIL 4324 Social and Political Philosophy" },
{ name: "PHIL 4325 Philosophy of Art and Aesthetics" },
{ name: "PHIL 4326 Major Figures" },
{ name: "PHIL 4327 Great Texts" },
{ name: "PHIL 4328 Philosophy Capstone Project" },
{ name: "PHIL 4330 Continental Philosophy" },
{ name: "PHIL 4331 Philosophy and Poetry" },
{ name: "PHIL 4332 Philosophy of Race" },
{ name: "PHIL 4333 Feminist Philosophy" },
{ name: "PHIL 4344 Philosophy of Science" },
{ name: "PHIL 4380 Topics in Philosophy" },
{ name: "PHIL 4V71 Independent Study in Philosophy" },
{ name: "PHIL 4V99 Senior Honors in Philosophy" },
{ name: "PHIN 1100 Sports Conditioning" },
{ name: "PHIN 1102 Racquetball" },
{ name: "PHIN 1103 Beginning Tennis" },
{ name: "PHIN 1104 Intermediate Tennis" },
{ name: "PHIN 1106 Aerobics" },
{ name: "PHIN 1107 Beginning Basketball" },
{ name: "PHIN 1108 Beginning Volleyball" },
{ name: "PHIN 1111 Special Topics in Physical Instruction" },
{ name: "PHIN 1120 Beginning Yoga" },
{ name: "PHIN 1121 Martial Arts and Self-Defense" },
{ name: "PHIN 1122 Physical Fitness and Conditioning" },
{ name: "PHIN 1129 Beginning Swimming" },
{ name: "PHIN 1130 Intermediate Swimming" },
{ name: "PHIN 2102 Racquetball II" },
{ name: "PHIN 2120 Open Water Scuba" },
{ name: "PHIN 2125 Intermediate Yoga" },
{ name: "PHIN 2150 Soccer" },
{ name: "PHIN 2151 Topics in Athletics" },
{ name: "PHIN 2152 Baseball" },
{ name: "PHIN 2153 Cheerleading" },
{ name: "PHIN 2154 Cross Country" },
{ name: "PHYS 1100 The Fun of Physics" },
{ name: "PHYS 1301 College Physics I" },
{ name: "PHYS 1302 College Physics II" },
{ name: "PHYS 2125 Physics Laboratory I" },
{ name: "PHYS 2126 Physics Laboratory II" },
{ name: "PHYS 2303 Contemporary Physics" },
{ name: "PHYS 2325 Mechanics" },
{ name: "PHYS 2326 Electromagnetism and Waves" },
{ name: "PHYS 2421 Honors Physics I - Mechanics and Heat" },
{ name: "PHYS 2422 Honors Physics II - Electromagnetism and Waves" },
{ name: "PHYS 3312 Classical Mechanics" },
{ name: "PHYS 3330 Numerical Methods in Physics and Computational Techniques" },
{ name: "PHYS 3380 Astronomy" },
{ name: "PHYS 3411 Theoretical Physics" },
{ name: "PHYS 3416 Electricity and Magnetism" },
{ name: "PHYS 3427 Electronics with Laboratory" },
{ name: "PHYS 4301 Quantum Mechanics I" },
{ name: "PHYS 4302 Quantum Mechanics II" },
{ name: "PHYS 4311 Thermodynamics and Statistical Mechanics" },
{ name: "PHYS 4319 Cyber-Physical Systems" },
{ name: "PHYS 4328 Optics" },
{ name: "PHYS 4352 Concepts of Modern Physics" },
{ name: "PHYS 4371 Solid State Physics" },
{ name: "PHYS 4373 Physical Measurements Laboratory" },
{ name: "PHYS 4386 Elementary Particle Physics" },
{ name: "PHYS 4390 Senior Research" },
{ name: "PHYS 4392 Extragalactic Astrophysics" },
{ name: "PHYS 4398 Senior Research Project for BA Degree" },
{ name: "PHYS 4399 Senior Honors Research in Physics" },
{ name: "PHYS 4V07 Senior Research Projects" },
{ name: "PHYS 4V10 Research Topics in Physics" },
{ name: "PHYS 4V11 Topics in Physics" },
{ name: "PPOL 4300 Capstone in Public Policy" },
{ name: "PPOL 4301 Law, Science, and Policy" },
{ name: "PPOL 4302 Data and Policy" },
{ name: "PPOL 4303 The Internet and Public Policy" },
{ name: "PPOL 4310 Law and Terrorism" },
{ name: "PPOL 4311 Wicked Social Problems" },
{ name: "PPOL 4312 Death Penalty, the Law and Public Policy" },
{ name: "PPOL 4314 Family Violence and Public Policy" },
{ name: "PPOL 4396 Topics in Public Policy" },
{ name: "PPOL 4398 Internship" },
{ name: "PPOL 4V91 Undergraduate Research in Public Policy" },
{ name: "PPOL 4V96 Independent Study" },
{ name: "PPOL 4V99 Senior Honors in Public Policy" },
{ name: "PSCI 3301 Political Theory" },
{ name: "PSCI 3303 Civil Liberties" },
{ name: "PSCI 3306 Political Economy" },
{ name: "PSCI 3310 Managing Government Organizations" },
{ name: "PSCI 3322 Constitutional Law" },
{ name: "PSCI 3323 American Federalism" },
{ name: "PSCI 3325 American Public Policy" },
{ name: "PSCI 3326 Politics and Business" },
{ name: "PSCI 3327 American Foreign Policy" },
{ name: "PSCI 3328 International Relations" },
{ name: "PSCI 3333 Political Behavior" },
{ name: "PSCI 3350 Comparative Politics" },
{ name: "PSCI 3351 Comparative Courts and Law" },
{ name: "PSCI 3353 Law and Gender" },
{ name: "PSCI 3362 The American Political Institutions" },
{ name: "PSCI 3364 Campaigns and Elections" },
{ name: "PSCI 4301 Law, Science, and Policy" },
{ name: "PSCI 4302 Data and Policy" },
{ name: "PSCI 4303 Ethnic Politics" },
{ name: "PSCI 4304 Energy and Environmental Politics and Policy" },
{ name: "PSCI 4305 Political Research" },
{ name: "PSCI 4306 Protest and Social Movements" },
{ name: "PSCI 4307 Predicting Politics" },
{ name: "PSCI 4308 Writing a Senior Thesis" },
{ name: "PSCI 4310 Law and Terrorism" },
{ name: "PSCI 4312 Politics of East Asia" },
{ name: "PSCI 4313 Politics of China" },
{ name: "PSCI 4314 Political Economy of East Asia" },
{ name: "PSCI 4316 Civil War and Conflict Resolution" },
{ name: "PSCI 4320 Political Psychology" },
{ name: "PSCI 4321 Media and Politics" },
{ name: "PSCI 4326 Political Parties and Interest Groups" },
{ name: "PSCI 4328 Fake News" },
{ name: "PSCI 4329 Global Politics" },
{ name: "PSCI 4330 The Bible and Politics" },
{ name: "PSCI 4331 Mexican Politics" },
{ name: "PSCI 4332 Latin American Politics" },
{ name: "PSCI 4334 Approaches to Peace" },
{ name: "PSCI 4341 Politics of the Judicial Process" },
{ name: "PSCI 4343 Congress and Public Policy" },
{ name: "PSCI 4344 Race and Redistricting" },
{ name: "PSCI 4347 The War on Drugs" },
{ name: "PSCI 4348 Terrorism" },
{ name: "PSCI 4354 Contemporary Political Thought" },
{ name: "PSCI 4356 International Political Economy" },
{ name: "PSCI 4357 Human Rights" },
{ name: "PSCI 4359 International Conflict" },
{ name: "PSCI 4360 The Political Economy of Multinational Corporations" },
{ name: "PSCI 4363 International Law" },
{ name: "PSCI 4364 Civil Rights Law and Society" },
{ name: "PSCI 4370 Policy Making Process" },
{ name: "PSCI 4372 Advocacy and Politics" },
{ name: "PSCI 4373 Politics of National Memory" },
{ name: "PSCI 4374 Policy Making in Austin" },
{ name: "PSCI 4375 Advocacy in the State Legislature" },
{ name: "PSCI 4376 Foreign Policy and Public Opinion" },
{ name: "PSCI 4377 Women and Politics" },
{ name: "PSCI 4378 Migration, Refugee and Asylum Policy and Law" },
{ name: "PSCI 4396 Selected Topics in Government and Politics" },
{ name: "PSCI 4398 Texas Legislative Internship" },
{ name: "PSCI 4V65 Undergraduate Research in Political Science" },
{ name: "PSCI 4V66 Mock Trial" },
{ name: "PSCI 4V67 Moot Court" },
{ name: "PSCI 4V76 Archer Center Washington Internship" },
{ name: "PSCI 4V97 Independent Study in Government and Politics" },
{ name: "PSCI 4V98 Internship" },
{ name: "PSCI 4V99 Senior Honors in Government and Politics" },
{ name: "PSY 2301 Introduction to Psychology" },
{ name: "PSY 2314 Lifespan Development" },
{ name: "PSY 2317 Statistics for Psychology" },
{ name: "PSY 2364 Animal Communication" },
{ name: "PSY 3100 Careers in Psychology" },
{ name: "PSY 3310 Child Development" },
{ name: "PSY 3322 Psychology of Adjustment" },
{ name: "PSY 3324 Psychology of Gender" },
{ name: "PSY 3331 Social Psychology" },
{ name: "PSY 3332 Social and Personality Development" },
{ name: "PSY 3333 Approaches to Clinical Psychology" },
{ name: "PSY 3336 Infancy" },
{ name: "PSY 3338 Adolescence" },
{ name: "PSY 3339 Educational Psychology" },
{ name: "PSY 3342 Exceptional Children" },
{ name: "PSY 3350 Psychology of Communication" },
{ name: "PSY 3351 Mass Communication and Behavior" },
{ name: "PSY 3355 Psychology of Creativity" },
{ name: "PSY 3356 The Creative Process" },
{ name: "PSY 3360 Historical Perspectives on Psychology: Mind and Machines since 1600" },
{ name: "PSY 3361 Cognitive Psychology" },
{ name: "PSY 3362 Cognitive Development" },
{ name: "PSY 3363 Evolution of Behavior" },
{ name: "PSY 3366 Motivation and Achievement" },
{ name: "PSY 3370 Positive Psychology" },
{ name: "PSY 3392 Research Design and Analysis" },
{ name: "PSY 3393 Experimental Projects in Psychology" },
{ name: "PSY 3490 Accelerated Quantitative Methods" },
{ name: "PSY 4098 Directed Research" },
{ name: "PSY 4099 Individual Study" },
{ name: "PSY 4193 Internship Preparation" },
{ name: "PSY 4320 Psychology of Reasoning" },
{ name: "PSY 4323 Cultural Diversity and Psychology" },
{ name: "PSY 4324 The Psychology of Prejudice" },
{ name: "PSY 4325 Death and Dying" },
{ name: "PSY 4327 Stress Management" },
{ name: "PSY 4328 Health Psychology" },
{ name: "PSY 4331 Personality" },
{ name: "PSY 4332 Psychology in the Workplace" },
{ name: "PSY 4333 Human Relations" },
{ name: "PSY 4343 Abnormal Psychology" },
{ name: "PSY 4344 Child Psychopathology" },
{ name: "PSY 4345 Violence in the Family" },
{ name: "PSY 4346 Human Sexuality" },
{ name: "PSY 4347 Marriage and Family Psychology" },
{ name: "PSY 4352 Emerging Adulthood Development" },
{ name: "PSY 4359 Cognitive Neuroscience" },
{ name: "PSY 4362 Perception" },
{ name: "PSY 4364 Attention and Memory" },
{ name: "PSY 4365 Psychology of Music" },
{ name: "PSY 4370 Industrial and Organizational Psychology" },
{ name: "PSY 4372 Forensic Psychology" },
{ name: "PSY 4373 Psychological Assessment" },
{ name: "PSY 4374 Judgment and Decision Making" },
{ name: "PSY 4377 Conflict Resolution" },
{ name: "PSY 4378 Advanced Conflict Resolution: Family Mediation" },
{ name: "PSY 4385 Neuropsychology" },
{ name: "PSY 4386 Adult Development and Aging" },
{ name: "PSY 4389 Developmental Cognitive Neuroscience" },
{ name: "PSY 4390 Directed Research and Writing" },
{ name: "PSY 4391 Individual Study and Writing" },
{ name: "PSY 4393 Language in Culture and Society" },
{ name: "PSY 4394 Internship in Psychology" },
{ name: "PSY 4395 Co-op Fieldwork" },
{ name: "PSY 4397 Thesis Research" },
{ name: "PSY 4V75 Honors Seminar" },
{ name: "PSY 4V90 Special Topics in Psychology" },
{ name: "PSY 4V91 Green Fellowship Directed Research" },
{ name: "PSY 4V96 Teaching Internship" },
{ name: "PSY 4V98 Directed Research" },
{ name: "PSY 4V99 Individual Study" },
{ name: "REAL 3305 Real Estate Principles" },
{ name: "REAL 3365 Real Estate Finance and Principles" },
{ name: "REAL 4090 Real Estate Internship" },
{ name: "REAL 4321 Real Estate Law and Contracts" },
{ name: "REAL 4328 Real Estate Valuation" },
{ name: "REAL 4V80 Internship in Real Estate" },
{ name: "REAL 4V90 Individual Study in Real Estate" },
{ name: "RHET 1302 Rhetoric" },
{ name: "RHET 2302 Intermediate Composition and Rhetoric" },
{ name: "RHET 3302 Visual Rhetoric" },
{ name: "RHET 3303 Professional and Technical Writing in Career Fields" },
{ name: "RHET 4302 Advanced Writing and Research" },
{ name: "RHET 4303 Advanced Editing" },
{ name: "RMIS 3370 Principles of Risk Management and Insurance" },
{ name: "RMIS 3375 Life, Accident and Health Insurance" },
{ name: "RMIS 4331 Business Liability Risk Management and Insurance" },
{ name: "RMIS 4332 Commercial Property Risk Management and Insurance" },
{ name: "RMIS 4333 Enterprise Risk Management" },
{ name: "RMIS 4334 Insurance Law and Contracts" },
{ name: "RMIS 4336 Risk Control and Safety Engineering" },
{ name: "RMIS 4338 Financial Decision Making Processes for Risk Management" },
{ name: "SE 2V95 Individual Instruction in Software Engineering" },
{ name: "SE 3162 Professional Responsibility in Computer Science and Software Engineering" },
{ name: "SE 3306 Mathematical Foundations of Software Engineering" },
{ name: "SE 3340 Computer Architecture" },
{ name: "SE 3341 Probability and Statistics in Computer Science and Software Engineering" },
{ name: "SE 3345 Data Structures and Introduction to Algorithmic Analysis" },
{ name: "SE 3354 Software Engineering" },
{ name: "SE 3377 C/C++ Programming in a UNIX Environment" },
{ name: "SE 3V95 Undergraduate Topics in Software Engineering" },
{ name: "SE 4347 Database Systems" },
{ name: "SE 4348 Operating Systems Concepts" },
{ name: "SE 4351 Requirements Engineering" },
{ name: "SE 4352 Software Architecture and Design" },
{ name: "SE 4367 Software Testing, Verification, Validation and Quality Assurance" },
{ name: "SE 4376 Object-Oriented Design" },
{ name: "SE 4381 Software Project Planning and Management" },
{ name: "SE 4399 Senior Honors in Software Engineering" },
{ name: "SE 4485 Software Engineering Project" },
{ name: "SE 4V95 Undergraduate Topics in Software Engineering" },
{ name: "SE 4V98 Undergraduate Research in Software Engineering" },
{ name: "SOC 1301 Introduction to Sociology" },
{ name: "SOC 2300 Introduction to Gender Studies" },
{ name: "SOC 2303 Culture, Media and Society" },
{ name: "SOC 2305 Individual and Society" },
{ name: "SOC 2320 Contemporary Social Issues" },
{ name: "SOC 3303 Classical Social Theory" },
{ name: "SOC 3305 Applied Data Analysis" },
{ name: "SOC 3315 Socio-Behavioral Sciences for Medicine" },
{ name: "SOC 3320 Urban Sociology" },
{ name: "SOC 3321 Deviance" },
{ name: "SOC 3325 Race and Ethnicity" },
{ name: "SOC 3331 Education and Society" },
{ name: "SOC 3333 Religion in Society" },
{ name: "SOC 3342 The Life Cycle" },
{ name: "SOC 3343 Sociology of the Family" },
{ name: "SOC 3344 Film and Society" },
{ name: "SOC 3352 Sex, Gender and Society" },
{ name: "SOC 3363 Immigrants and Immigration in U.S. Society" },
{ name: "SOC 3379 Diversity in the Public Sector" },
{ name: "SOC 3381 Field Research Methods" },
{ name: "SOC 3382 Sustainable Communities" },
{ name: "SOC 4302 Class, Status, and Power" },
{ name: "SOC 4306 Advanced Sociological Research" },
{ name: "SOC 4317 Field Research Practicum" },
{ name: "SOC 4357 Drugs, Alcohol and Society" },
{ name: "SOC 4369 Public Health and Society" },
{ name: "SOC 4371 Mental Health and Illness" },
{ name: "SOC 4372 Health and Illness" },
{ name: "SOC 4375 Gender and Work" },
{ name: "SOC 4384 Social Epidemiology" },
{ name: "SOC 4385 Global Health and Society" },
{ name: "SOC 4386 Health and Social Policy" },
{ name: "SOC 4388 Religions in Global Societies" },
{ name: "SOC 4396 Selected Topics in Sociology" },
{ name: "SOC 4V97 Independent Study in Sociology" },
{ name: "SOC 4V98 Internship" },
{ name: "SOC 4V99 Senior Honors in Sociology" },
{ name: "SPAN 1311 Beginning Spanish I" },
{ name: "SPAN 1312 Beginning Spanish II" },
{ name: "SPAN 2310 Spanish for Heritage Speakers" },
{ name: "SPAN 2311 Intermediate Spanish I" },
{ name: "SPAN 2312 Intermediate Spanish II" },
{ name: "SPAN 3330 Medical Spanish" },
{ name: "SPAN 3350 Business Spanish" },
{ name: "SPAN 3363 Spanish Composition and Style" },
{ name: "SPAN 3365 Advanced Spanish I" },
{ name: "SPAN 3366 Advanced Spanish II" },
{ name: "SPAN 4301 Advanced Spanish Conversation" },
{ name: "SPAN 4302 Spanish Conversation and Community" },
{ name: "SPAN 4315 Study Abroad in Spanish" },
{ name: "SPAN 4364 Advanced Spanish Culture" },
{ name: "SPAN 4366 Spanish and Latin American Literatures" },
{ name: "SPAN 4367 The History of the Spanish Language" },
{ name: "SPAU 3301 Communication Disorders" },
{ name: "SPAU 3303 Normal Language Development" },
{ name: "SPAU 3304 Communication Sciences" },
{ name: "SPAU 3305 Language and Literacy Development" },
{ name: "SPAU 3340 Articulation Disorders" },
{ name: "SPAU 3341 Audiology" },
{ name: "SPAU 3342 Exceptional Children" },
{ name: "SPAU 3343 Phonetics" },
{ name: "SPAU 3344 Anatomy and Physiology of Speech and Hearing" },
{ name: "SPAU 3345 Neural Basis of Communication" },
{ name: "SPAU 3388 Clinical Observation in Speech-Language Pathology" },
{ name: "SPAU 3390 Clinical Practicum in Speech-Language Pathology" },
{ name: "SPAU 4193 Internship Preparation" },
{ name: "SPAU 4308 Language Disorders in Children" },
{ name: "SPAU 4342 Assessment Procedures in Speech-Language Pathology" },
{ name: "SPAU 4366 Clinical Report Writing" },
{ name: "SPAU 4367 Development and Design of Therapeutic Resources" },
{ name: "SPAU 4386 Adult Development and Aging" },
{ name: "SPAU 4393 Language in Culture and Society" },
{ name: "SPAU 4394 Multicultural Aspects of Communication Disorders" },
{ name: "SPAU 4395 Issues in the Management of Persons with Hearing Impairment" },
{ name: "SPAU 4396 Internship" },
{ name: "SPAU 4397 Thesis Research" },
{ name: "SPAU 4V75 Honors Seminar" },
{ name: "SPAU 4V90 Special Topics in Speech-Language Pathology and Audiology" },
{ name: "SPAU 4V96 Teaching Internship" },
{ name: "SPAU 4V98 Directed Research" },
{ name: "SPAU 4V99 Individual Study" },
{ name: "STAT 1342 Statistical Decision Making" },
{ name: "STAT 2332 Introductory Statistics for Life Sciences" },
{ name: "STAT 3332 Statistics for Life Sciences" },
{ name: "STAT 3341 Probability and Statistics in Computer Science and Software Engineering" },
{ name: "STAT 3355 Data Analysis for Statisticians and Actuaries" },
{ name: "STAT 3360 Probability and Statistics for Management and Economics" },
{ name: "STAT 4351 Probability" },
{ name: "STAT 4352 Mathematical Statistics" },
{ name: "STAT 4354 Numerical and Statistical Computing" },
{ name: "STAT 4355 Applied Linear Models" },
{ name: "STAT 4360 Introduction to Statistical Learning" },
{ name: "STAT 4382 Stochastic Processes" },
{ name: "STAT 4475 Capstone Project" },
{ name: "STAT 4V02 Independent Study in Statistics" },
{ name: "STAT 4V97 Undergraduate Topics in Statistics" },
{ name: "THEA 1310 Understanding Theatre" },
{ name: "THEA 1351 Acting 1" },
{ name: "THEA 1352 Acting 2" },
{ name: "THEA 2311 Topics in Theatre" },
{ name: "THEA 2350 Voice for the Actor" },
{ name: "THEA 2364 Musical Theatre Workshop" },
{ name: "THEA 2371 Technical Theatre 1" },
{ name: "THEA 2372 Improvisation" },
{ name: "THEA 2373 Languages of the Body" },
{ name: "THEA 2V71 Independent Study in Theatre" },
{ name: "THEA 3310 Theatre/Performance Ensemble" },
{ name: "THEA 3323 Performance in Historical Context" },
{ name: "THEA 3324 Technical Theatre 2" },
{ name: "THEA 3325 Directing" },
{ name: "THEA 3342 Advanced Topics in Theatre" },
{ name: "THEA 3351 Light Design" },
{ name: "THEA 3352 Stage Management" },
{ name: "THEA 3353 Theatrical Makeup" },
{ name: "THEA 3356 Acting 3" },
{ name: "THEA 3361 Performing Shakespeare" },
{ name: "THEA 3372 Advanced Improvisation" },
{ name: "THEA 3380 Theatre and Literature" },
{ name: "THEA 3V10 Technical Theatre Practicum" },
{ name: "THEA 4301 Acting for Film and Video" },
{ name: "THEA 4310 Theatre Internship" },
{ name: "THEA 4342 Advanced Topics in Technical Theatre" },
{ name: "THEA 4V71 Independent Study in Theatre" },
{ name: "UNIV 1010 Freshman Seminar" },
{ name: "UNIV 1100 Freshman Seminar - Undeclared" },
{ name: "UNIV 2010 Seminar for Pre-Law Professionals" },
{ name: "UNIV 2012 Seminar: Evaluating Competencies for Entry to Health Professions Schools" },
{ name: "UNIV 2020 Core Curriculum Assessment" },
{ name: "UNIV 2074 Introductory University Engagement and Applied Leadership I" },
{ name: "UNIV 2075 Introductory University Engagement and Applied Leadership II" },
{ name: "UNIV 2114 Portfolio Seminar for UT PACT Students" },
{ name: "UNIV 2V96 Special Topics I" },
{ name: "UNIV 3010 Transfer Transitions" },
{ name: "UNIV 3015 Travel Preparation" },
{ name: "UNIV 3074 Intermediate University Engagement and Applied Leadership I" },
{ name: "UNIV 3075 Intermediate University Engagement and Applied Leadership II" },
{ name: "UNIV 3112 Research Practicum" },
{ name: "UNIV 3310 Community-Engaged Service Learning" },
{ name: "UNIV 4073 Advanced University Engagement and Applied Leadership I" },
{ name: "UNIV 4074 Student Leadership" },
{ name: "UNIV 4075 Advanced University Engagement and Applied Leadership II" },
{ name: "UNIV 4076 Advanced Student Leadership" },
{ name: "UNIV 4174 Theory and Practice of Group Motivation and Leadership" },
{ name: "UNIV 4395 Topics: Online Education in a Massively Multiplayer Virtual World" },
{ name: "VPAS 2V71 Independent Study in Visual and Performing Art" },
{ name: "VPAS 3300 Elements of Art and Performance" },
{ name: "VPAS 3340 Writing in the Arts" },
{ name: "VPAS 4310 Entertainment Law" },
{ name: "VPAS 4389 Theory and Practice of the Visual and Performing Arts" },
{ name: "VPAS 4390 Senior Capstone" },
{ name: "VPAS 4V71 Independent Study in Visual and Performing Art" },
{ name: "VPAS 4V99 Senior Honors in Visual and Performing Art" }
]