export const actionTypes = {
  ADD_COURSE: 'ADD_COURSE',
  DELETE_COURSE: 'DELETE_COURSE',
  LOAD_COURSE: 'LOAD_COURSE',
  EXPORT_COURSES: 'EXPORT_COURSES',
  EXPORT_TO_EXCEL: 'EXPORT_TO_EXCEL',
  EDIT_COURSE: 'EDIT_COURSE',
  SET_BOARD: 'SET_BOARD',
  ADD_PDF_COURSES: 'ADD_PDF_COURSES',
  DRAG_EVENT: 'DRAG_EVENT',
  ADD_EXTRA_YEAR: 'ADD_EXTRA_YEAR',
  DELETE_EXTRA_YEAR: 'DELETE_EXTRA_YEAR',
};